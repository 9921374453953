import { useEffect } from "react";
import { Link } from "react-router-dom";
import useWindowSize from "../../utils/useWindowSize";
import "./ErrorPage.scss";

interface ErrorPageProps {
  setShowNavBars: (setVal: boolean) => void;
}

const getBtnResponsiveClass = (width: number): string => {
  return `error-btn-container`;
};
const getErrorPageContainerClass = (width: number): string => {
  return `error-page-container`;
};
const mobileOrDesktop = (width: number): string => {
  return `error-page`;
};

const ErrorPage = ({ setShowNavBars }: ErrorPageProps) => {
  const { width } = useWindowSize();

  useEffect(() => {
    setShowNavBars(false);
  }, [setShowNavBars]);

  return (
    <div className={`hacktober-page ${mobileOrDesktop(width)}`}>
      <div className={getErrorPageContainerClass(width)}>
        <div className={getBtnResponsiveClass(width)}>
          <a
            href="mailto: EMAIL@slalom.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="app-button app-button-secondary">
              <div className="btn-label">{`CONTACT US`}</div>
            </button>
          </a>
          <Link to="/">
            <button
              className="app-button app-button-primary"
              onClick={() => setShowNavBars(true)}
            >
              <div className="btn-label-primary">Return Home</div>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
