import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import TopNavigationBar from "../../components/TopNavigationBar/TopNavigationBar";
import { Participant } from "../../models/participant.model";
import { EventStatus } from "../../utils/eventUtil";
import AboutPage from "../AboutPage/AboutPage";
import ErrorPage from "../ErrorPage/ErrorPage";
import EventsPage from "../EventsPage/EventsPage";
import FAQsPage from "../FAQsPage/FAQsPage";
import HomePage from "../HomePage/HomePage";
import PrelaunchPage from "../PrelaunchPage/PrelaunchPage";
import SplashPage from "../SplashPage/SplashPage";
import WinnersPage from "../WinnersPage/WinnersPage";
import WrapPage from "../WrapPage/WrapPage";

type EventViewProps = {
  showNavBars: boolean;
  setShowNavBars: React.Dispatch<React.SetStateAction<boolean>>;
  eventStatus?: EventStatus;
};

export const ParticipantContext: React.Context<Participant> =
  React.createContext(null);

/**
    This is used as an introductory page and links to view the upcoming event calendar and FAQs.
    There is no home page because the Question of the Day / Leaderboard should only show
    during the event dates.
  */
const Prelaunch: React.FC<EventViewProps> = ({
  setShowNavBars,
}): React.ReactElement => (
  <Switch>
    <Route exact path="/" render={() => <PrelaunchPage />} />
    <Route
      path="/events"
      render={() => <EventsPage showClaimPoints={true} isPrelaunch={true} />}
    />
    <Route path="/faqs" render={() => <FAQsPage isPrelaunch={true} />} />
    <Route path="/about" render={() => <AboutPage isPrelaunch={true} />} />
    <Route render={() => <ErrorPage setShowNavBars={setShowNavBars} />} />
  </Switch>
);

/**
    This is used as the main view for the event. This will include all of the
    interactivity and components used during the event.
  */
const FullSite: React.FC<EventViewProps> = ({
  showNavBars,
  setShowNavBars,
}): React.ReactElement => (
  <>
    {showNavBars && <TopNavigationBar />}
    <Switch>
      <Route exact path="/" render={() => <HomePage />} />
      <Route
        path="/events"
        render={() => <EventsPage showClaimPoints={true} />}
      />
      <Route path="/about" render={() => <AboutPage />} />
      <Route path="/faqs" render={() => <FAQsPage />} />
      <Route render={() => <ErrorPage setShowNavBars={setShowNavBars} />} />
    </Switch>
    {showNavBars && <Footer />}
  </>
);

/**
    This is used as an announcements/summary page for when the event has completed.
  */
const EndAnnouncements: React.FC<EventViewProps> = ({
  setShowNavBars,
  showNavBars,
}): React.ReactElement => (
  <>
    <Switch>
      <Route
        exact
        path="/"
        render={() => (
          <WrapPage
            text="Thank you for making this another successful year for Slalom City Limits! We hope you all walk away from the festival feeling informed, inspired, and connected securely. 
          Want to relive the fun moments or hoping to catch a session you missed? Check out the recordings in Workday! Views, comments, and ratings on the Workday recordings will all go towards the Slalom City Limits Artist winners. 
          Keep an eye out on the Slalom City Limits SharePoint for the announcement of Artist and #1 fan winners. "
          />
        )}
      />
      <Route path="/about" render={() => <AboutPage />} />
      <Route render={() => <ErrorPage setShowNavBars={setShowNavBars} />} />
    </Switch>
  </>
);

/**
    This is used to display the winners and prizes for the event.
    It will consist of a limited version of the full site design.
  */
const Winners: React.FC<EventViewProps> = ({
  setShowNavBars,
  showNavBars,
}): React.ReactElement => (
  <>
    {showNavBars && <TopNavigationBar />}
    <Switch>
      <Route exact path="/">
        <Redirect to="/winners" />
      </Route>
      <Route path="/about" render={() => <AboutPage />} />
      <Route render={() => <WinnersPage />} />
    </Switch>
    {showNavBars && <Footer />}
  </>
);

const EventView: React.FC<EventViewProps> = ({
  showNavBars,
  setShowNavBars,
  eventStatus,
}): React.ReactElement => {
  switch (eventStatus) {
    case EventStatus.Prelaunch:
      return (
        <Prelaunch showNavBars={showNavBars} setShowNavBars={setShowNavBars} />
      );
    case EventStatus.InProgress:
      return (
        <FullSite showNavBars={showNavBars} setShowNavBars={setShowNavBars} />
      );
    case EventStatus.EndAnnouncement:
      return (
        <EndAnnouncements
          showNavBars={showNavBars}
          setShowNavBars={setShowNavBars}
        />
      );
    case EventStatus.Winners:
      return (
        <Winners showNavBars={showNavBars} setShowNavBars={setShowNavBars} />
      );
    default:
      <SplashPage />;
  }
};

export default EventView;
