import React from "react";
import { NavLink } from "react-router-dom";
import { shouldShowWinners } from "../../../utils/eventUtil";
import { ENavLink } from "../TopNavigationBar";
import "./NavOptions.scss";

const NavOptions: React.FC = (): JSX.Element => {
  const navOptions: { title: string; type: ENavLink }[] = shouldShowWinners()
    ? [
        { title: "Home", type: ENavLink.Home },
        { title: "About", type: ENavLink.About },
      ]
    : [
        { title: "Home", type: ENavLink.Home },
        { title: "About", type: ENavLink.About },
        { title: "Events", type: ENavLink.Events },
        { title: "FAQs", type: ENavLink.Faqs },
      ];
  return (
    <div className="nav-options">
      {navOptions.map((navOption) => {
        const { title } = navOption;
        return (
          <NavLink
            className="nav-option"
            key={title}
            exact={title === "Home"}
            to={
              title !== "Home"
                ? `/${title.toLowerCase().replace(/ /g, "")}`
                : "/"
            }
          >
            <span className="nav-link-text">{title}</span>
          </NavLink>
        );
      })}
    </div>
  );
};

export default NavOptions;
