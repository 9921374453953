import { FC } from "react";
import { Market } from "../../../models/market.model";
import { ParticipantRank } from "../../../models/participant.model";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../../utils/useWindowSize";
import TopLeaders from "../../TopLeaders/TopLeaders";
import {
  LeaderBoardOptions,
  LeaderboardOrFallbackProps,
} from "../Leaderboard.interface";
import LoadLeaderListing from "./LoadLeaderListing";

const { MOBILE_MAX } = deviceWidthBreakpoints;

const renderLeaderBoard = (
  isLocalLeaderBoard: boolean,
  isWinnersLeaderBoard: boolean,
  width: number,
  data: ParticipantRank[] | Market[],
  selectedOption: LeaderBoardOptions,
  error,
  isLoading: boolean
) => (
  <>
    <TopLeaders
      width={width}
      data={data}
      selectedOption={selectedOption}
      error={error}
      isLoading={isLoading}
    />
    {width > MOBILE_MAX &&
      !isLocalLeaderBoard &&
      (!isWinnersLeaderBoard || selectedOption === "Global") && (
        <LoadLeaderListing
          width={width}
          data={data}
          selectedOption={selectedOption}
          error={error}
          isLoading={isLoading}
        />
      )}
    {width > MOBILE_MAX &&
      !isLocalLeaderBoard &&
      isWinnersLeaderBoard &&
      selectedOption === "Global"}
  </>
);

const renderFallbackMessage = () => (
  <div className="leaderboard-default-msg">
    Want to see your name on the leaderboard? Check out our other ways to earn
    points to get started!
  </div>
);

const LeaderboardOrFallback: FC<LeaderboardOrFallbackProps> = ({
  isLocalLeaderBoard,
  isWinnersLeaderBoard,
  data,
  selectedOption,
  error,
  isLoading,
}) => {
  const { width } = useWindowSize();
  return data && data?.length > 0
    ? renderLeaderBoard(
        isLocalLeaderBoard,
        isWinnersLeaderBoard,
        width,
        data,
        selectedOption,
        error,
        isLoading
      )
    : renderFallbackMessage();
};

export default LeaderboardOrFallback;
