import { FC } from "react";
import { Market } from "../../../models/market.model";
import { ParticipantRank } from "../../../models/participant.model";
import { deviceWidthBreakpoints } from "../../../utils/useWindowSize";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { LoadLeaderListingProps } from "../Leaderboard.interface";
import LeaderListing from "./LeaderListing";

const { TABLET_MAX } = deviceWidthBreakpoints;

const leaderListingContainerClassName = (width) =>
  `leader-listing-container${width > TABLET_MAX ? "" : "-mobile"}`;

const LoadLeaderListing: FC<LoadLeaderListingProps> = ({
  width,
  data,
  selectedOption,
  error,
  isLoading,
}) => {
  const isMarket = selectedOption === "Market";
  return (
    <div className={leaderListingContainerClassName(width)}>
      {data &&
        data.slice(3, 10).map((_data: ParticipantRank | Market, idx) => (
          <LoadingSpinner
            key={idx}
            isLoading={isLoading}
            error={error}
            color={"#00A4F9"}
            size={"small"}
          >
            <LeaderListing
              key={
                isMarket
                  ? (_data as Market).name
                  : (_data as ParticipantRank).user
              }
              data={_data}
              type={selectedOption}
              rank={idx + 4}
              lastRow={data.length === idx + 1}
            />
            <div className="leaderboard-gap" />
          </LoadingSpinner>
        ))}
    </div>
  );
};

export default LoadLeaderListing;
