import moment from "moment-timezone";
import { ContentLinkParserParams } from "../ContentLinkParser/ContentLinkParser.interface";

// Time logic strings
const zone_name: string = moment.tz.guess();

const startTime = (startDateTime: string) =>
  moment.tz(startDateTime, zone_name).format("LLLL");

const endTime = (endDateTime: string) =>
  moment.tz(endDateTime, zone_name).format("LLLL");

export const startTimeStr = (startDateTime: string) =>
  startTime(startDateTime)
    .slice(startTime(startDateTime).indexOf(":") - 2)
    .trim();

export const endTimeStr = (endDateTime: string) =>
  endTime(endDateTime).slice(endTime(endDateTime).indexOf(":") - 2);

export const dateStr = (startDateTime: string) =>
  startTime(startDateTime)
    .slice(
      startTime(startDateTime).indexOf(" "),
      startTime(startDateTime).indexOf(":") - 2
    )
    .trim();

export const weekdayStr = (startDateTime: string) =>
  startTime(startDateTime).slice(0, startTime(startDateTime).indexOf(","));

export const timezoneStr = moment.tz(zone_name).zoneAbbr();

// Event content logic
export const shortenContent = (content: string, maxLength: number) => {
  if (content.length > maxLength) {
    const trimmedString = content.slice(0, maxLength);
    return (
      trimmedString.slice(
        0,
        Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
      ) + "..."
    );
  } else {
    return content;
  }
};

export const cleanUpName = (name: string) => {
  const regex = /^\[([^\]]+)\]\s*/;
  const match = regex.exec(name);
  return match ? name.replace(regex, "").trim() : name;
};

export const getMonth = (startDateTime: string) => {
  return dateStr(startDateTime).split(" ")[0].substring(0, 3);
};

export const getDay = (startDateTime: string) => {
  let date = dateStr(startDateTime).split(" ")[1].split(",")[0];

  return parseInt(date) < 10 ? `0${date}` : date;
};

export const getTime = (startDateTime: string) => {
  return startTimeStr(startDateTime).split(" ").join("").toLocaleLowerCase();
};

// parser params
export const getParams: ContentLinkParserParams = [
  {
    regex:
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
    type: "link",
    label: "{input here}",
    url: "{input here}",
  },
  {
    regex: /zenith@slalom.com/,
    type: "mail",
    label: "zenith@slalom.com",
    url: "zenith@slalom.com",
  },
];
