import React from "react";
//import ScrollToTopIcon from "../../assets/images/back-to-top.svg";
import topArrow from "../../assets/images/left arrow-white.svg";
import "./ScrollToTopButton.scss";

const ScrollToTopButton: React.FC = (): React.ReactElement => (
  <div className="scroll-to-top-container">
    <button
      className="scroll-to-top-button"
      onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
    >
      <img
        className="scroll-to-top-icon"
        src={topArrow}
        alt="scroll to top icon"
      />
    </button>
  </div>
);

export default ScrollToTopButton;
