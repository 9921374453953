import {
  ProgramMember,
  SortedProgramMembers,
} from "../../models/programMembers.model";

export const sortProgramMembers = (programMembers: ProgramMember[]) => {
  const sortedProgramMembers: SortedProgramMembers[] = [
    { teamName: "Leadership", teamMembers: [] },
  ];
  programMembers.forEach((programMember, index) => {
    const findTeam = sortedProgramMembers.find(
      (value) => value.teamName === programMember.programMemberTeamTypeName
    );

    if (typeof findTeam === "undefined") {
      sortedProgramMembers.push({
        teamName: programMember.programMemberTeamTypeName,
        teamMembers: [programMember],
      });
    } else {
      const findMember = findTeam.teamMembers.find(
        (value) => value.memberId === programMember.memberId
      );
      if (typeof findMember === "undefined") {
        const indexOfTeam = sortedProgramMembers.indexOf(findTeam);
        sortedProgramMembers[indexOfTeam].teamMembers.push(programMember);
      }
    }
  });

  return sortedProgramMembers;
};
