import React, { FC, useState } from "react";
import { useMutation } from "react-query";
import { postLikeEvent } from "../../ApiHelper";
import KudosGivenIcon from "../../assets/images/kudos-given-icon.svg";
import KudosDefaultIcon from "../../assets/images/kudos-thumbsup-icon.svg";
import Button from "./Button";
import { KudosButtonProps } from "./Buttons.interface";
import "./Buttons.scss";

const KudosButton: FC<KudosButtonProps> = ({
  isGiveKudos: isGiveKudos,
  eventId,
  activityLikeId,
}): React.ReactElement => {
  const [isKudosGiven, setIsKudosGiven] = useState(isGiveKudos);
  const [shouldButtonBeDisabled, setShouldButtonBeDisabled] =
    useState(isKudosGiven);

  const [mutate] = useMutation(postLikeEvent, {
    onSuccess: (data) => {
      setIsKudosGiven(data.liked); // update state based on response
    },
  });

  const submitKudos = () => {
    setShouldButtonBeDisabled(true);
    mutate({
      eventId,
      activityLikeId,
      liked: true,
    });
  };

  const buttonClassList: string[] = ["kudos-button"];

  if (isKudosGiven) {
    buttonClassList.push("kudos-button--submitted");
  }

  const buttonValue = isKudosGiven ? "KUDOS GIVEN" : "GIVE KUDOS";
  const iconSrc = isKudosGiven ? KudosGivenIcon : KudosDefaultIcon;

  return (
    <Button
      type="submit"
      disabled={shouldButtonBeDisabled}
      onClick={submitKudos}
      value={buttonValue}
      classes={buttonClassList}
      iconSrc={iconSrc}
      style={isKudosGiven ? "primary" : "secondary"}
    />
  );
};

export default KudosButton;
