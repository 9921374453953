import { FC } from "react";
import Tooltip from "../Tooltip/Tooltip";
import "./SectionHeader.scss";

type SectionHeaderProps = {
  title: string;
  width: number;
  tooltipContent?: any;
};

const SectionHeader: FC<SectionHeaderProps> = ({
  title,
  width,
  tooltipContent,
}: SectionHeaderProps): JSX.Element => {
  return (
    <div className="section-header">
      <div className={`section-header__title `}>
        {/* <div className={`section-header__circle `}></div>
        <div className={`section-header__icon `}></div> */}
        {title}
        {/* <div className={`section-header__icon flip `}></div>
        <div className={`section-header__circle `}></div> */}
      </div>
      {tooltipContent ? <Tooltip>{tooltipContent}</Tooltip> : <></>}
    </div>
  );
};

export default SectionHeader;
