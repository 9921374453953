import React from "react";
import { NavLink } from "react-router-dom";
import { ParticipantRank } from "../../../models/participant.model";
import { deviceWidthBreakpoints } from "../../../utils/useWindowSize";
import { ENavLink } from "../TopNavigationBar";
import "./NavHamburgerMenu.scss";

const { TABLET_MAX } = deviceWidthBreakpoints;

type NavHamburgerMenuProps = {
  width: number;
  participantData: ParticipantRank;
  navLinks: ENavLink[];
  menuOpen: boolean;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const NavHamburgerMenu: React.FC<NavHamburgerMenuProps> = ({
  width,
  participantData,
  navLinks,
  menuOpen,
  setMenuOpen,
}): React.ReactElement => {
  const navMenuClasses = ["nav-menu"];

  if (width <= TABLET_MAX) {
    menuOpen
      ? navMenuClasses.push("nav-menu--open")
      : navMenuClasses.push("nav-menu--closed");
  }

  return (
    <div className="nav-menu-mobile">
      <div
        id="nav-icon"
        onClick={() => setMenuOpen(!menuOpen)}
        className={menuOpen ? "open" : ""}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <ul className={navMenuClasses.join(" ")}>
        <li className="nav-menu__rankings">
          <div>
            <div>Local: #{participantData?.marketRank || "X"} </div>
          </div>
          <div>
            <div>Global: #{participantData?.globalRank || "X"} </div>
          </div>
          <div>
            <div>Points {participantData?.totalPoints || "0"} </div>
          </div>
        </li>

        {navLinks.map((link, index) => (
          <li
            className="nav-menu__item"
            key={`nav-menu-item-${index}}`}
            onClick={() => setMenuOpen(false)}
          >
            <NavLink
              className="nav-menu__link"
              exact={link === "Home"}
              to={
                link !== "Home"
                  ? `/${link.toLowerCase().replace(/ /g, "")}`
                  : "/"
              }
            >
              {link}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavHamburgerMenu;
