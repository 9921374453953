import React, { useState } from "react";
import upArrow from "../../assets/images/expand_less.png";
import Card from "../Card/Card";
import ContentLinkParser from "../ContentLinkParser/ContentLinkParser";
import "./FAQTile.scss";
import { getParams } from "./FAQTile.util";

type FAQType = {
  title: string;
  body: Array<{ itemHeader?: string; itemText: string }>;
};
interface FAQTileProps {
  faq: FAQType;
}

interface FaqTileImageProps {
  isCollapsed: boolean;
}

const params = getParams;

const FaqTileImage: React.FC<FaqTileImageProps> = ({
  isCollapsed,
}): JSX.Element => {
  return (
    <div>
      <img
        src={upArrow}
        alt="up-arrow-icon"
        className={`faq-tile-arrow-icon faq-tile-arrow-icon-${isUpOrDown(
          isCollapsed
        )}`}
      />
    </div>
  );
};

const renderCollapsedFAQTile = ({ title }: FAQType): JSX.Element => {
  return (
    <div className="faq-tile-title-container">
      <div className="faq-tile-title">{title}</div>
      <FaqTileImage isCollapsed={true} />
    </div>
  );
};

const renderExpandedFAQTile = ({ title, body }: FAQType): JSX.Element => {
  return (
    <React.Fragment>
      <div className="faq-tile-title-container">
        <div className="faq-tile-title">{title}</div>
        <FaqTileImage isCollapsed={false} />
      </div>
      {body.map((item) =>
        item.itemHeader ? (
          <div className="faq-tile-header-container" key={`REGEX-${item}`}>
            <div style={{ fontWeight: "bold" }}>{item.itemHeader}</div>
            <ContentLinkParser content={item.itemText} params={params} />
          </div>
        ) : (
          <div className="faq-tile-body-container" key={item.itemText}>
            <ContentLinkParser content={item.itemText} params={params} />
          </div>
        )
      )}
    </React.Fragment>
  );
};

const renderCollapsedOrExpandedFAQTile = (faq, isCollapsed): JSX.Element => {
  return isCollapsed ? renderCollapsedFAQTile(faq) : renderExpandedFAQTile(faq);
};

const isUpOrDown = (isCollapsed) => {
  return isCollapsed ? "up" : "down";
};

const FAQTile = ({ faq }: FAQTileProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleIsCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <div className="faq-tile-container" onClick={handleIsCollapsed}>
      <Card containerStyle={{ marginBottom: "16px" }}>
        {renderCollapsedOrExpandedFAQTile(faq, isCollapsed)}
      </Card>
    </div>
  );
};
export default FAQTile;
