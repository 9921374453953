import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import faqHeroLg from "../../assets/images/pages/FAQ_Desktop_1440.png";
import faqHeroXl from "../../assets/images/pages/FAQ_Desktop_2560.png";
import faqHeroSm from "../../assets/images/pages/FAQ_Mobile.png";
import faqHeroMd from "../../assets/images/pages/FAQ_Tablet.png";
import FAQList from "../../components/FAQList/FAQList";
import IntersectionObserverWrapper from "../../components/IntersectionObserverWrapper/IntersectionObserverWrapper";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import useScrollEventListener from "../../hooks/useScrollEventListener";
import { Event } from "../../utils/consts/event";
import { getBanner } from "../../utils/imageUtils";
import {
  getMainContainerClass,
  getPageContainerClass,
  getResponsiveBackgroundClass,
} from "../../utils/responsiveClassHelpers";
import useWindowSize from "../../utils/useWindowSize";
import "./FAQsPage.scss";

const banners = [faqHeroXl, faqHeroLg, faqHeroMd, faqHeroSm];

type FAQsPageProps = {
  isPrelaunch?: boolean;
};

const FAQsPage: React.FC<FAQsPageProps> = ({ isPrelaunch }) => {
  const { width } = useWindowSize();

  const getFaqHeaderResponsiveClass = (width) => `faq-header`;

  const event = Event;

  const [tocSticky, setTocSticky] = useState(false);
  const [visibleSection, setVisibleSection] = useState(null);
  const [elements, setElements] = useState<{
    [key: string]: { isVisible: boolean; top: number };
  }>({});
  useScrollEventListener(setTocSticky, 390);

  const handleVisibilityChange = (
    id: string,
    isVisible: boolean,
    top: number
  ) => {
    setElements((prev) => {
      const prevElement = prev[id];
      if (
        prevElement &&
        prevElement.isVisible === isVisible &&
        prevElement.top === top
      ) {
        return prev;
      }
      return { ...prev, [id]: { isVisible, top } };
    });
  };

  useEffect(() => {
    const visibleElements = Object.entries(elements).filter(
      ([_, value]) => value.isVisible
    );
    if (visibleElements.length > 0) {
      const topElement = visibleElements.reduce((prev, current) =>
        prev[1].top < current[1].top ? prev : current
      );
      setVisibleSection(topElement[0]);
    }
  }, [elements]);

  return (
    <PageWrapper
      bannerImage={getBanner(width, banners)}
      isPrelaunch={isPrelaunch}
    >
      <div
        className={`hacktober-page faq-page ${getResponsiveBackgroundClass(
          width
        )}`}
      >
        <div className={getPageContainerClass(width)}>
          <div className={getMainContainerClass(width)}>
            <div className="section-container">
              <div
                className={`faq-content-wrapper ${tocSticky ? " sticky" : ""} `}
              >
                <div className={`table-of-contents `}>
                  <p className="title">Jump to:</p>
                  {event.faqSections.map((val, index) => (
                    <a
                      key={`faq-section-${index}`}
                      href={`#${val.name.replaceAll(" ", "_")}`}
                      className={
                        val.name.replaceAll(" ", "_") === visibleSection
                          ? "active"
                          : ""
                      }
                    >
                      {val.name}
                    </a>
                  ))}
                </div>
                <div className={`faq-sections-wrapper `}>
                  {event.faqSections.map((val, index) => (
                    <IntersectionObserverWrapper
                      key={index}
                      elementId={val.name.replaceAll(" ", "_")}
                      className="faq-section-wrapper"
                      onVisibilityChange={handleVisibilityChange}
                    >
                      <div>
                        <div
                          className={`faq-header ${getFaqHeaderResponsiveClass(
                            width
                          )}`}
                        >
                          <p>{val.name}</p>
                        </div>
                        <FAQList faqs={val.sectionData} />
                      </div>
                    </IntersectionObserverWrapper>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

FAQsPage.propTypes = {
  isPrelaunch: PropTypes.bool,
};

export default FAQsPage;
