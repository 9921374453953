import React, { FC, useRef } from "react";
import calendarIcon from "../../assets/images/calendar-purple.svg";
import qaIcon from "../../assets/images/qa-icon.svg";
import trophyIcon from "../../assets/images/trophy-purple.svg";
import useDetectOutsideClick from "../../hooks/useDetectOutsideClick";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";
import Button from "../Buttons/Button";
import Confetti from "../Confetti/Confetti";
import "./WelcomeModal.scss";

const { MOBILE_MAX } = deviceWidthBreakpoints;

type WelcomeModalProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const WelcomeModal: FC<WelcomeModalProps> = ({
  show,
  setShow,
}): React.ReactElement => {
  const modalRef = useRef();
  const { width } = useWindowSize();

  useDetectOutsideClick(modalRef, () => setShow(false));

  const handleClick = (e?: any) => {
    e.preventDefault();
    setShow(false);
  };

  return (
    <>
      <div className={`welcome-modal ${show ? "active" : ""}`}>
        <div ref={modalRef} className="welcome-modal-container">
          <div className="section-header">
            <div className="section-title welcome-title">
              How to Earn Points
            </div>
          </div>
          <div className="welcome-modal-body">
            <div className="welcome-modal-section">
              <img
                src={qaIcon}
                className="welcome-modal-section__image"
                alt="questions and answers"
              ></img>
              <div className="welcome-modal-section__content">
                <div className="welcome-modal-section__content__title">
                  Answer the question of the day
                </div>
                {width > MOBILE_MAX && (
                  <div className="welcome-modal-section__content__body">
                    Answer the question of the day correctly to earn points!
                    Questions refresh every 12 hours, and points are
                    automatically added.
                  </div>
                )}
              </div>
            </div>
            <div className="welcome-modal-section">
              <img
                src={calendarIcon}
                className="welcome-modal-section__image"
                alt="calendar"
              ></img>
              <div className="welcome-modal-section__content">
                <div className="welcome-modal-section__content__title">
                  Attend events to get a code
                </div>
                {width > MOBILE_MAX && (
                  <div className="welcome-modal-section__content__body">
                    Attend events throughout the week! Each event will provide
                    you with a special code. Enter that code in the “Events”
                    section of our site to earn points!
                  </div>
                )}
              </div>
            </div>
            <div className="welcome-modal-section">
              <img
                src={trophyIcon}
                className="welcome-modal-section__image"
                alt="trophy"
              ></img>

              <div className="welcome-modal-section__content">
                <div className="welcome-modal-section__content__title">
                  Other ways to earn points
                </div>
                {width > MOBILE_MAX && (
                  <div className="welcome-modal-section__content__body">
                    Check out the “Other Ways to Earn Points” section at the
                    bottom of the home page for more ways to earn some extra
                    points. Some of these point-earning activities may not have
                    points added automatically; they will be added by the end of
                    the event.
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="welcome-modal-button-container">
            <Button
              type="button"
              onClick={handleClick}
              value="Okay, let's go!"
            />{" "}
          </div>
        </div>
      </div>
      {show && <Confetti />}
    </>
  );
};

export default WelcomeModal;
