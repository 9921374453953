/* eslint-disable react/no-unescaped-entities */
import PropTypes from "prop-types";
import React from "react";
import trophyIcon from "../../assets/images/trophy-purple.png";
import LinkButton from "../../components/Buttons/LinkButton";
import Card from "../Card/Card";
import SectionHeader from "../SectionHeader/SectionHeader";
import "./OtherWaysToParticipate.scss";

const CLAIM_POINTS_TEXT = "Claim points";
const MAIL_TO_YOUR_EVENT_ADMIN = "mailto:<YOUR EVENT EMAIL HERE>";

type OtherWaysCardProps = {
  header: string;
  content: string | (string | React.ReactElement)[];
  points: number;
  buttonText?: string;
  buttonLink?: string;
};

const OtherWaysCard: React.FC<OtherWaysCardProps> = ({
  header,
  content,
  points,
  buttonText,
  buttonLink,
}) => (
  <Card
    containerStyle={{
      width: "46%",
      display: "inline-flex",
    }}
    contentStyle={{
      padding: "24px",
      borderColor: "white",
    }}
    containerClass="tile-width"
  >
    <div className="other-ways-to-earn-tile-content">
      <div className="tile-title">{header}</div>
      <div className="tile-points">
        <img src={trophyIcon} className="trophy-icon" alt="trophy icon" />
        <div className="points-value">{`${points} Points`}</div>
      </div>
      <div className="tile-description">{content}</div>
    </div>
    <div className="tile-button-container">
      <LinkButton
        text={buttonText ? buttonText : CLAIM_POINTS_TEXT}
        style="primary"
        href={buttonLink ? buttonLink : MAIL_TO_YOUR_EVENT_ADMIN}
        newTab={true}
      />
    </div>
  </Card>
);

OtherWaysCard.propTypes = {
  header: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  points: PropTypes.number,
};

const OtherWaysToParticipate = () => {
  return (
    <div className="section-container">
      <SectionHeader
        title={"Other ways to earn points"}
        width={0}
      ></SectionHeader>
      <div className="other-ways-to-earn-container">
        <OtherWaysCard
          header="Join Slalom on 1Password"
          content={[
            "Request an invite from security@slalom.com and mention it is part of Slalom City Limits in order to earn points!",
            <br key="break1" />,
            <br key="break2" />,
            <i key="1password already joined">
              If you have already signed-up for 1Password, your points will be
              added soon.
            </i>,
          ]}
          points={300}
          buttonText="Join 1Password"
          buttonLink="mailto:security@slalom.com"
          key={"1Password"}
        />
        <OtherWaysCard
          header="Submit an idea for Hacktober 2024!"
          content={
            "Have an idea for a site feature or activity for Hacktober? Complete the Sparkthink below to earn a unique code for extra points and have a chance to have your idea come to life in Hacktober! "
          }
          points={300}
          buttonText="Submit Hacktober Idea"
          buttonLink="https://sparkthink.slalom.com/survey/s/5a8256cc-aaf4-4095-8d79-9aff8cc43922"
        />
      </div>
    </div>
  );
};

export default OtherWaysToParticipate;
