import React, { Dispatch, SetStateAction } from "react";
import useWindowSize from "../../utils/useWindowSize";
import "./Tabs.scss";

export interface TabOption {
  title: string;
  type: string;
}

interface TabProps {
  selectedOption: string;
  setSelectedOption: Dispatch<SetStateAction<TabProps["selectedOption"]>>;
  tabOptions: TabOption[];
}

export const Tabs: React.FC<TabProps> = ({
  selectedOption,
  setSelectedOption,
  tabOptions,
}: TabProps): JSX.Element => {
  const isSelectedStyleApplied = (option: string) =>
    option === selectedOption ? "selected-tab-option" : "unselected-tab-option";
  const { width } = useWindowSize();

  return (
    <div className="tab-container">
      <div className="tabs-options">
        {tabOptions.map((leaderboardOption) => {
          const { title, type } = leaderboardOption;
          return (
            <div
              key={title}
              className={`tabs-option ${isSelectedStyleApplied(type)}`}
              onClick={() => setSelectedOption(type)}
            >
              {title}
            </div>
          );
        })}
      </div>
    </div>
  );
};
