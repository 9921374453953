import { Link } from "react-router-dom";
import mdBackground from "../../assets/images/pages/prelaunch-md.png";
import smBackground from "../../assets/images/pages/prelaunch-sm.png";
import xlBackground from "../../assets/images/pages/prelaunch-xl.png";
import Button from "../../components/Buttons/Button";
import { Event } from "../../utils/consts/event";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";
import "./PrelaunchPage.scss";

const event = Event;

const { SM, MD, LG } = deviceWidthBreakpoints;
const lineup = [
  { date: "Day 1 - July 15th", lineup: event.preLaunchPage.lineup[0] },
  { date: "Day 2 - July 16th", lineup: event.preLaunchPage.lineup[1] },
  { date: "Day 3 - July 17th", lineup: event.preLaunchPage.lineup[2] },
  { date: "Day 4 - July 18th", lineup: event.preLaunchPage.lineup[3] },
];

const renderBackgroundMedia = (width: number) =>
  width > LG
    ? renderXlBackground()
    : width > MD
    ? renderLgBackground()
    : width > SM
    ? renderMdBackground()
    : renderSmBackground();

const renderSmBackground = (): JSX.Element => (
  <img
    src={smBackground}
    alt="forest background"
    className="prelaunch-background-image"
  ></img>
);

const renderMdBackground = (): JSX.Element => (
  <img
    src={mdBackground}
    alt="forest background"
    className="prelaunch-background-image"
  ></img>
);

const renderLgBackground = (): JSX.Element => (
  <img
    src={xlBackground}
    alt="forest background"
    className="prelaunch-background-image"
  ></img>
);

const renderXlBackground = (): JSX.Element => (
  <img
    src={xlBackground}
    alt="forest background"
    className="prelaunch-background-image"
  ></img>
);

const PrelaunchPage = () => {
  const { width } = useWindowSize();

  return (
    <>
      <main className="prelaunch-background">
        <main className="prelaunch-container">
          {/* {renderBackgroundMedia(width)} */}
          <section className="prelaunch-container__buttons">
            <Link to="/events">
              <Button
                style="primary"
                type="button"
                value="REGISTER FOR SESSIONS"
                onClick={() => null}
              />
            </Link>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={event.preLaunchPage.spotifyPlayList}
              className="app-button app-button-primary spotify-button"
            >
              FESTIVAL PLAYLIST
            </a>
            <Link to="/faqs">
              <Button
                style="primary"
                type="button"
                value="FAQ"
                onClick={() => null}
              />
            </Link>
          </section>
          <section className="prelaunch-container__set-list">
            {lineup.map((entry, idx) => {
              return (
                <div
                  className="prelaunch-container__set-list__day-entry"
                  key={`lineup-day-${idx}`}
                >
                  <div className="prelaunch-container__set-list__day-entry__date">
                    {entry.date}
                  </div>
                  <div className="prelaunch-container__set-list__day-entry__list">
                    {entry.lineup.map((speaker, index) => (
                      <div
                        className="prelaunch-container__set-list__day-entry__list__speaker"
                        key={`day-${idx}-speaker-${index}`}
                      >
                        {speaker}
                      </div>
                    ))}
                  </div>
                  {idx !== 3 && <div className="divider-prelaunch"></div>}
                </div>
              );
            })}
          </section>
        </main>
      </main>
    </>
  );
};

export default PrelaunchPage;
