import { FC, useRef, useState } from "react";
import { queryCache, useMutation } from "react-query";
import { postEventClaim } from "../../ApiHelper";
import desktopImage from "../../assets/images/claim-points-desktop.png";
import mobileImage from "../../assets/images/claim-points-mobile.png";
import tabletImage from "../../assets/images/claim-points-tablet.png";
import { KEY_CODE_ENTER } from "../../utils/consts/keyCode";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";
import Button from "../Buttons/Button";
import SectionHeader from "../SectionHeader/SectionHeader";
import "./ClaimPoints.scss";

enum ClaimState {
  Default = "default",
  Success = "success",
  Invalid = "invalid",
}

const EVENT_CODE_LENGTH = 6;

const ClaimPoints: FC = () => {
  const [claimState, setClaimState] = useState(ClaimState.Default);
  const [claimsCode, setClaimsCode] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const inputRef = useRef(null);

  const { width } = useWindowSize();

  const { SM, MD } = deviceWidthBreakpoints;

  const getClaimPointsImage = (width: number) =>
    width > MD ? desktopImage : width > SM ? tabletImage : mobileImage;

  const getFormClass = (width) => `claim-points-form-inline`;
  const getDescriptionContainerClass = (width) =>
    `description-container-content`;
  const getInputClass = () =>
    claimState === ClaimState.Invalid
      ? "claim-points-input-error"
      : claimState === ClaimState.Success
      ? "claim-points-input-success"
      : "";
  const getInputMessageClass = () =>
    claimState === ClaimState.Invalid
      ? "claim-points-input-message-error"
      : claimState === ClaimState.Success
      ? "claim-points-input-message-success"
      : "";
  const [saveEventClaim] = useMutation(postEventClaim, {
    onSuccess: (data) => {
      setClaimState(data !== null ? ClaimState.Success : ClaimState.Invalid);
      console.log(data);
    },
    onSettled: () => {
      setSubmitLoading(false);
      queryCache.invalidateQueries("participant");
      queryCache.invalidateQueries("participants");
    },
  });

  const handleEnter = (event: {
    preventDefault: () => void;
    keyCode: number;
  }) => {
    if (
      event.keyCode !== KEY_CODE_ENTER ||
      document.activeElement !== inputRef.current
    ) {
      return;
    }

    handleSubmitEventClaim(event);
  };

  const handleSubmitEventClaim = (event: {
    preventDefault: () => void;
  }): void => {
    event.preventDefault();
    if (claimsCode.length !== EVENT_CODE_LENGTH) {
      return;
    }

    setSubmitLoading(true);
    saveEventClaim(claimsCode);
  };

  const handleClaimsCodeOnChange = (value: string): void => {
    const code = value?.toUpperCase() ?? "";

    setClaimsCode(code);
    setClaimState(ClaimState.Default);
  };

  return (
    <section className="section-container">
      <SectionHeader title={"Claim Points"} width={width}></SectionHeader>
      <div className="claim-points">
        <div className={`claim-points-content`}>
          <div className="claim-points__header">
            Attended an event? Claim your points now!
          </div>
          <div className={`claim-points__description`}>
            Enter your unique code here to earn points! If you&apos;ve lost your
            event code, ping your event lead so you don&apos;t miss out on
            points!
          </div>
          <form className={`claim-points-form`}>
            <div className={`claim-points-enter-code-container`}>
              <input
                ref={inputRef}
                className={`claim-points-input ${getInputClass()}`}
                type="text"
                name="code"
                maxLength={EVENT_CODE_LENGTH}
                onChange={(event) =>
                  handleClaimsCodeOnChange(event.target.value)
                }
                placeholder="Enter Event Code"
                value={claimsCode}
                onKeyDown={handleEnter}
              />
              <div
                className={`claim-points-input-message ${getInputMessageClass()}`}
              >
                {claimsCode.length !== EVENT_CODE_LENGTH
                  ? "Complete section to continue"
                  : claimState === ClaimState.Invalid
                  ? "Uh oh! Please try again."
                  : claimState === ClaimState.Success
                  ? "Success!"
                  : " "}
              </div>
            </div>
            <div className={`claim-points-submit-container`}>
              <div className={`claim-points-button-container`}>
                <Button
                  type="button"
                  onClick={handleSubmitEventClaim}
                  value="Submit"
                  disabled={
                    claimsCode.length !== EVENT_CODE_LENGTH ||
                    claimState === ClaimState.Invalid ||
                    claimState === ClaimState.Success
                  }
                  loading={submitLoading}
                  classes={width < MD && ["full-width"]}
                />
              </div>
            </div>
          </form>
        </div>
        <div className="claim-points-image-container">
          <img
            className="claim-points-image"
            src={getClaimPointsImage(width)}
            alt="hand holding microphone"
          />
        </div>
      </div>
    </section>
  );
};

export default ClaimPoints;
