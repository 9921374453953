import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/slalom-logo-white.svg";
import "./NavLogo.scss";

type NavLogoProps = {
  width: number;
  wrap?: boolean;
};

const NavLogo: React.FC<NavLogoProps> = ({ width, wrap }: NavLogoProps) => {
  return (
    <NavLink to="/" className={`nav-logo-content ${wrap && "top-left"}`}>
      <img src={logo} alt="logo" />
    </NavLink>
  );
};

export default NavLogo;
