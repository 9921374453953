import React from "react";
import { Market } from "../../models/market.model";
import { ParticipantRank } from "../../models/participant.model";
import { getDataImage } from "../../utils/imageUtils";
import { LeaderBoardOptions } from "../Leaderboard/Leaderboard.interface";
import "./TopLeaderCard.scss";

type LeaderCardProps = {
  type: LeaderBoardOptions;
  data: ParticipantRank | Market;
  position: number;
};

const TopLeaderCard: React.FC<LeaderCardProps> = ({
  type,
  data,
  position,
}): React.ReactElement => {
  const isMarket = type === "Market";
  return (
    <div className={`top-leader-card top-leader-card--${position}`}>
      <div className="top-leader-content top-leader-shadow">
        {isMarket ? (
          <>
            <h2 className="top-leader-stat-position-points top-leader-stat-h2-market">
              #{position + 1}
            </h2>
            <h3 className="top-leader-stat-h2 top-leader-stat-h2-market">
              {(data as Market).name}
            </h3>
            <h3 className="top-leader-stat-position-points">
              {(data as Market).totalPoints}
            </h3>
          </>
        ) : (
          <>
            <h2 className="top-leader-stat-position-points">#{position + 1}</h2>
            <img
              className="top-leader-avatar"
              src={getDataImage((data as ParticipantRank).image)}
              alt="avatar"
            />
            <h2 className="top-leader-stat-h2">
              {(data as ParticipantRank).firstName}
            </h2>
            <h2 className="top-leader-stat-h2">
              {(data as ParticipantRank).lastName}
            </h2>
            <h3 className="top-leader-stat-h3">
              {(data as ParticipantRank).market}
            </h3>
            <h3 className="top-leader-stat-position-points">
              {(data as ParticipantRank).totalPoints}
            </h3>
          </>
        )}
      </div>
      <div className={`position-image-container`}>
        <div className={`position-image image-${position}`} />
      </div>
    </div>
  );
};

export default TopLeaderCard;
