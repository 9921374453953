import { FC } from "react";
import { Loader } from "semantic-ui-react";
import { ButtonProps } from "./Buttons.interface";
import "./Buttons.scss";

const Button: FC<ButtonProps> = ({
  style,
  type,
  value,
  disabled,
  loading,
  iconSrc,
  classes,
  onClick,
  ref,
}): React.ReactElement => {
  let buttonStyle = style || "primary";
  const buttonClasses: string[] = (classes || []).concat([
    "app-button",
    `app-button-${buttonStyle}`,
  ]);

  return (
    <div>
      <button
        ref={ref}
        className={buttonClasses.filter(Boolean).join(" ")}
        type={type}
        onClick={onClick}
        disabled={disabled || loading}
      >
        {loading ? (
          <Loader active={true} size={"small"} inline={"centered"} />
        ) : (
          <>
            {iconSrc && (
              <img
                className="app-button__icon"
                src={iconSrc}
                alt="button icon"
              />
            )}
            <span>{value}</span>
          </>
        )}
      </button>
    </div>
  );
};

export default Button;
