import { useContext } from "react";
import { ParticipantContext } from "../../App";
import endHeroLg from "../../assets/images/pages/EndHome_Desktop_1440.png";
import endHeroXl from "../../assets/images/pages/EndHome_Desktop_2560.png";
import endHeroSm from "../../assets/images/pages/EndHome_Mobile.png";
import endHeroMd from "../../assets/images/pages/EndHome_Tablet.png";
import Card from "../../components/Card/Card";
import Leaderboard from "../../components/Leaderboard/Leaderboard";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { Event } from "../../utils/consts/event";
import { getBanner } from "../../utils/imageUtils";
import useWindowSize from "../../utils/useWindowSize";
import "./WinnersPage.scss";

const WinnersPage = () => {
  const { width } = useWindowSize();
  const banners = [endHeroXl, endHeroLg, endHeroMd, endHeroSm];
  const event = Event;
  const participant = useContext(ParticipantContext);

  return (
    <PageWrapper bannerImage={getBanner(width, banners)}>
      <div className="page-container">
        <section>
          <SectionHeader
            title={"Thank you for participating!"}
            width={width}
          ></SectionHeader>
          <Card>
            {event.winnersPage.winnersEventAnnouncementText}{" "}
            <a href={event.winnersPage.recordingsLink} className="workday-link">
              Workday
            </a>
            .
          </Card>
          <div className={"contact-section-container"}>
            <Card containerClass="contact-card">
              <div className="contact-section-title">Got feedback?</div>
              {event.winnersPage.winnersEventsFeedbackText}
              <div
                className={"about-page-btn-container about-page-card-footer"}
              >
                <a
                  href="mailto: zenith@slalom.com."
                  target="_blank"
                  rel="noopener noreferrer"
                  className="app-button-primary"
                >
                  CONTACT US
                </a>
              </div>
            </Card>
            <Card containerClass="contact-card">
              <div className="contact-section-title">
                Join the Zenith Build Team!
              </div>
              {event.winnersPage.winnersEventsJoinText}
              <div
                className={"about-page-btn-container about-page-card-footer"}
              >
                <a
                  href="https://sparkthink.slalom.com/login?referrer=https://sparkthink.slalom.com/vote/637bb5761487c50001e91428&auth=slalom&accessCode=35c6266e-78c0-46d5-b519-151351845bf3&collectorId=6661ca3bfe6f8800012e2f25"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="app-button-primary"
                >
                  JOIN US
                </a>
              </div>
            </Card>
            <Card containerClass="contact-card">
              <div className="contact-section-title">Caught a bug?</div>
              {event.winnersPage.winnersEventsBugText}
              <div
                className={"about-page-btn-container about-page-card-footer"}
              >
                <a
                  href="mailto: zenith@slalom.com."
                  target="_blank"
                  rel="noopener noreferrer"
                  className="app-button-primary"
                >
                  CONTACT US
                </a>
              </div>
            </Card>
          </div>
        </section>
        <section>
          <Leaderboard
            isLocalLeaderBoard={false}
            isWinnersLeaderBoard={true}
            participantData={participant}
          />
        </section>
        <section>
          <SectionHeader title={"Top 3 Fans"} width={width}></SectionHeader>
          <Card>{event.winnersPage.winnersEventsFansText}</Card>
        </section>
        <section>
          <SectionHeader title={"Top 3 Artists"} width={width}></SectionHeader>
          <Card>{event.winnersPage.winnersEventsArtistsText}</Card>
        </section>
      </div>
    </PageWrapper>
  );
};

export default WinnersPage;
