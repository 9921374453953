import moment from "moment";
import React from "react";
import EventCard from "../../components/EventCard/EventCard";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { Event } from "../../models/event.model";
import { DayFilter, MarketFilter, TimeFilter } from "../../models/filter.model";
import { Participant } from "../../models/participant.model";
import { FactActivityTypes } from "../../utils/consts/factActivityTypes";
import { RelativeTime, filterEvents } from "./EventsPage.util";

export interface EventCardGridProps {
  eventsAreLoading: boolean;
  eventsError: string | null;
  eventsData: Event[];
  selectedMarkets?: MarketFilter[];
  selectedDays: DayFilter[];
  selectedTimes: TimeFilter[];
  selectedTopics?: string[];
  speakerProfiles: Participant[];
  setSpeakerProfiles: React.Dispatch<React.SetStateAction<Participant[]>>;
  relativeTimeFilter?: RelativeTime;
}

const EventCardGrid: React.FC<EventCardGridProps> = ({
  eventsAreLoading,
  eventsError,
  eventsData,
  selectedMarkets,
  selectedDays,
  selectedTimes,
  selectedTopics,
  speakerProfiles,
  setSpeakerProfiles,
  relativeTimeFilter = RelativeTime.FUTURE,
}) => {
  return (
    <div className="event-card-grid">
      <LoadingSpinner
        isLoading={eventsAreLoading}
        error={eventsError}
        size="massive"
        color="#443A84"
      >
        {filterEvents(
          eventsData,
          selectedTopics,
          selectedMarkets,
          selectedDays,
          selectedTimes
        ).map((event) => {
          const shouldDisplayEvent =
            relativeTimeFilter === RelativeTime.FUTURE
              ? moment(event.endDateTime).isSameOrAfter(moment())
              : moment(event.endDateTime).isBefore(moment());
          // Find the object in the array at event.likeCategories that has an likeTypeId of 1
          const likeStatus = event.likeCategories.find(
            (likeCategory) => likeCategory.likeTypeId === FactActivityTypes.like
          );

          return (
            shouldDisplayEvent && (
              <EventCard
                key={event.eventId}
                {...event}
                speakerProfiles={speakerProfiles}
                setSpeakerProfiles={setSpeakerProfiles}
                isPastEvent={relativeTimeFilter === RelativeTime.PAST}
                isOngoing={
                  shouldDisplayEvent &&
                  moment().isSameOrAfter(event.startDateTime) &&
                  moment().isSameOrBefore(event.endDateTime)
                }
                eventId={event.eventId}
                isLiked={likeStatus ? likeStatus.liked : false}
                activityLikeId={likeStatus ? likeStatus.likeId : undefined}
                maxLength={231}
              />
            )
          );
        })}
      </LoadingSpinner>
    </div>
  );
};

export default EventCardGrid;
