export const Markets = {
  chicago: "Chicago",
  seattle: "Seattle",
  minneapolis: "Minneapolis",
  charlotte: "Charlotte",
  philadelphia: "Philadelphia",
  manchester: "Manchester",
  atlanta: "Atlanta",
  boston: "Boston",
  sydney: "Sydney",
  london: "London",
  denver: "Denver",
  dallas: "Dallas",
  austin: "Austin",
  nyc: "New York City",
  vancouver: "Vancouver",
  losAngeles: "Los Angeles",
  australia: "Australia",
  detroit: "Detroit",
  toronto: "Toronto",
  columbus: "Columbus",
  sanFrancisco: "San Francisco",
  montreal: "Montreal",
  southFlorida: "South Florida",
  portland: "Portland",
  eastBay: "East Bay",
  stLouis: "St. Louis",
  oc: "Orange County",
  siliconValley: "Silicon Valley",
  houston: "Houston",
  global: "Global",
  hartford: "Hartford",
  fortWorth: "Fort Worth",
};
