import React from "react";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import useWindowSize from "../../utils/useWindowSize";
import "./PageBanner.scss";

type PageBannerProps = {
  bannerImage: string;
};

const PageBanner: React.FC<PageBannerProps> = ({
  bannerImage,
}): React.ReactElement => {
  const { width } = useWindowSize();

  return (
    <div className="page-banner">
      <img
        src={bannerImage}
        alt="Themed banner unique to each page"
        className={`page-banner-img page-banner-img-${getResponsiveClassName(
          width
        )}`}
      />
    </div>
  );
};

export default PageBanner;
