import React, { useState } from "react";
import closeIcon from "../../assets/images/close-icon.png";
import "./Announcements.scss";

type AnnouncementsPropTypes = {
  announcementData: { id: string; text: string }[];
};
const Announcements: React.FC<AnnouncementsPropTypes> = ({
  announcementData,
}): React.ReactElement => {
  const [announcements, setAnnouncements] = useState(announcementData);

  const handleCloseAnnouncement = (announcementId: string) => {
    setAnnouncements(announcements.filter(({ id }) => id !== announcementId));
    sessionStorage.setItem(`seenAnnouncement${announcementId}`, "true");
  };

  return (
    <div className="announcement-container">
      {announcements.map(({ id, text }) => {
        return (
          !sessionStorage.getItem(`seenAnnouncement${id}`) && (
            <div key={`announcement-${id}`} className="announcement">
              {text}
              <img
                className="announcement-close"
                src={closeIcon}
                alt="x to close"
                onClick={() => handleCloseAnnouncement(id)}
              />
            </div>
          )
        );
      })}
    </div>
  );
};

export default Announcements;
