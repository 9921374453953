import { ContentLinkParserParams } from "../ContentLinkParser/ContentLinkParser.interface";

export const getParams: ContentLinkParserParams = [
  {
    regex: /prismatica@slalom.com/,
    type: "mail",
    label: "prismatica@slalom.com",
    url: "prismatica@slalom.com",
  },
  {
    regex: /infosec@slalom.com/,
    type: "mail",
    label: "infosec@slalom.com",
    url: "infosec@slalom.com",
  },
  {
    regex: /security@slalom.com/,
    type: "mail",
    label: "security@slalom.com",
    url: "security@slalom.com",
  },
  {
    regex: /zenith@slalom.com/,
    type: "mail",
    label: "zenith@slalom.com",
    url: "zenith@slalom.com",
  },
  {
    regex: /slalomtalent@slalom.com/,
    type: "mail",
    label: "slalomtalent@slalom.com",
    url: "slalomtalent@slalom.com",
  },
  {
    regex: /microsoft-form-here/,
    type: "link",
    label: "here",
    url: "https://forms.office.com/r/SnpEb782b7",
  },
  {
    regex: /slalomcitylimits@slalom.com/,
    type: "mail",
    label: "slalomcitylimits@slalom.com",
    url: "slalomcitylimits@slalom.com",
  },
];
