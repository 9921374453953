import React, { useEffect, Dispatch, SetStateAction } from "react";

export default function useScrollEventListener(
  stateToUpdate: Dispatch<SetStateAction<boolean>>,
  scrollY: number
): void {
  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > scrollY) {
        stateToUpdate(true);
      } else {
        stateToUpdate(false);
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);
}
