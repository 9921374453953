import React, { useContext } from "react";
import { ParticipantContext } from "../../App";
import homeHeroLg from "../../assets/images/pages/Home_Desktop_1440.png";
import homeHeroXl from "../../assets/images/pages/Home_Desktop_2560.png";
import homeHeroSm from "../../assets/images/pages/Home_Mobile.png";
import homeHeroMd from "../../assets/images/pages/Home_Tablet.png";
import Donate from "../../components/Donate/Donate";
import Leaderboard from "../../components/Leaderboard/Leaderboard";
import OtherWaysToParticipate from "../../components/OtherWaysToParticipate/OtherWaysToParticipate";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import QuizOfTheDay from "../../components/QuizOfTheDay/QuizOfTheDay";
import { getBanner } from "../../utils/imageUtils";
import useWindowSize from "../../utils/useWindowSize";
import "./HomePage.scss";

const banners = [homeHeroXl, homeHeroLg, homeHeroMd, homeHeroSm];
// const HomePage: React.FC = (): React.ReactElement => {
//   const participant = useContext(ParticipantContext);

const HomePage: React.FC = (): React.ReactElement => {
  const participant = useContext(ParticipantContext);
  const { width } = useWindowSize();

  return (
    <PageWrapper bannerImage={getBanner(width, banners)}>
      <div className="page-container">
        <QuizOfTheDay />
        <Leaderboard
          isLocalLeaderBoard={undefined}
          participantData={participant}
          isWinnersLeaderBoard={undefined}
        />
        <Donate />
        <OtherWaysToParticipate />
      </div>
    </PageWrapper>
  );
};
export default HomePage;
