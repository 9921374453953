import PropTypes from "prop-types";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getProgramMembers } from "../../ApiHelper";
import aboutHeroLg from "../../assets/images/pages/About_Desktop_1440.png";
import aboutHeroXl from "../../assets/images/pages/About_Desktop_2560.png";
import aboutHeroSm from "../../assets/images/pages/About_Mobile.png";
import aboutHeroMd from "../../assets/images/pages/About_Tablet.png";
import AboutInfoList from "../../components/AboutInfoList/AboutInfoList";
import Button from "../../components/Buttons/Button";
import Card from "../../components/Card/Card";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { Event } from "../../utils/consts/event";
import { getBanner } from "../../utils/imageUtils";
import useWindowSize from "../../utils/useWindowSize";
import "./AboutPage.scss";
import { sortProgramMembers } from "./AboutPage.util";

const banners = [aboutHeroXl, aboutHeroLg, aboutHeroMd, aboutHeroSm];

const event = Event;

type AboutPageProps = {
  isPrelaunch?: boolean;
};

const openInNewTab = (url) => {
  window.open(url, "_blank", "noreferrer");
};

const AboutPage: React.FC<AboutPageProps> = ({ isPrelaunch }) => {
  const {
    data: programMembers,
    error: programMembersError,
    isLoading: loadingProgramMembers,
  } = useQuery(["programMembers"], getProgramMembers, {
    refetchOnWindowFocus: false,
  });

  const sortedProgramMembers = useMemo(() => {
    if (!loadingProgramMembers) {
      return sortProgramMembers(programMembers);
    }
    return [];
  }, [programMembers]);

  const { width } = useWindowSize();
  const url =
    "https://sparkthink.slalom.com/survey/s/35c6266e-78c0-46d5-b519-151351845bf3";
  return (
    <PageWrapper
      bannerImage={getBanner(width, banners)}
      isPrelaunch={isPrelaunch}
    >
      <div className="page-container">
        <div className="section-container">
          <section className="about-welcome">
            <section className="welcome-section">
              <SectionHeader
                title={"Welcome to Slalom City Limits!"}
                width={width}
              ></SectionHeader>
              <div
                className={"about-notes-section welcome-section about-welcome"}
              >
                <Card>
                  <div className="about-page-text-body-container">
                    <div className="about-section-title">
                      About {event.eventName}
                    </div>
                    {event.aboutPage.eventIntroText}
                  </div>
                </Card>
              </div>
            </section>
          </section>
        </div>
        <section className={"contact-section-container"}>
          <Card containerClass="contact-card">
            <div className="about-page-card-text-body-container">
              <div className="contact-section-title">Got feedback?</div>
              {event.aboutPage.aboutEventsFeedbackText}
              <div
                className={"about-page-btn-container about-page-card-footer"}
              >
                <a
                  href="mailto: slalomcitylimits@slalom.com."
                  target="_blank"
                  rel="noopener noreferrer"
                  className="app-button-primary"
                >
                  CONTACT US
                </a>
              </div>
            </div>
          </Card>
          <Card containerClass="contact-card">
            <div className="about-page-card-text-body-container">
              <div className="contact-section-title">
                Join the Zenith Build Team!
              </div>
              {event.aboutPage.aboutEventsJoinText}
              <div
                className={"about-page-btn-container about-page-card-footer"}
              >
                <Link to={url} target="_blank">
                  <Button
                    style="primary"
                    type="button"
                    value="Join Us"
                    onClick={() => openInNewTab(url)}
                  />
                </Link>
              </div>
            </div>
          </Card>
          <Card containerClass="contact-card">
            <div className="about-page-card-text-body-container">
              <div className="contact-section-title">Caught a bug?</div>
              {event.aboutPage.aboutEventsBugText}
              <div
                className={"about-page-btn-container about-page-card-footer"}
              >
                <a
                  href="mailto: zenith@slalom.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="app-button-primary"
                >
                  CONTACT US
                </a>
              </div>
            </div>
          </Card>
        </section>
        {sortedProgramMembers.map((team, index) => {
          return (
            <div key={`about-page-team-${index}`}>
              <div className="about-header">{team.teamName}</div>
              <AboutInfoList participants={team.teamMembers} />
            </div>
          );
        })}
      </div>
    </PageWrapper>
  );
};

AboutPage.propTypes = {
  isPrelaunch: PropTypes.bool,
};

export default AboutPage;
