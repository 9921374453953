import { FC } from "react";
import { Icon } from "semantic-ui-react";
import { IconSizeProp } from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";

interface LoadingSpinnerProps {
  isLoading: boolean;
  error?: any;
  children?: any;
  color?: string;
  size: IconSizeProp;
}

const LoadingSpinner: FC<LoadingSpinnerProps> = ({
  isLoading,
  error,
  children,
  color,
  size,
}) =>
  isLoading ? (
    <Icon style={{ color: color }} loading name="spinner" size={size} />
  ) : error ? (
    <div>{"An error has occurred: " + error?.message}</div>
  ) : (
    children
  );

export default LoadingSpinner;
