import { FC, ReactNode } from "react";
import { ContentLinkParserParams } from "./ContentLinkParser.interface";

type ContentLinkParserProps = {
  content: string;
  params: ContentLinkParserParams;
};

const ContentLinkParser: FC<ContentLinkParserProps> = ({ content, params }) => {
  const parseString = (str: string): ReactNode => {
    const segments = str.split(" ");
    return segments.map((segment, index) => {
      const matchedParam = params.find((param) => param.regex.test(segment));
      return matchedParam && matchedParam.type === "link" ? (
        <a href={matchedParam.url} target="_blank" rel="noopener noreferrer">
          {matchedParam.label}
        </a>
      ) : matchedParam && matchedParam.type === "mail" ? (
        <a
          href={`mailto:${matchedParam.url}`}
          target="_blank"
          rel="noopener noreferrer"
          key={`REGEX-${index}`}
        >
          {matchedParam.label + " "}
        </a>
      ) : matchedParam && matchedParam.type === "nav" ? (
        <a
          href={`${window.location.origin}/${matchedParam.url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {matchedParam.label + " "}
        </a>
      ) : (
        segment + " "
      );
    });
  };

  return <div>{parseString(content)}</div>;
};

export default ContentLinkParser;
