import React from "react";
import chatIcon from "../../assets/images/chat-white.svg";
import flagIcon from "../../assets/images/flag-white.svg";
import mailIcon from "../../assets/images/mail-white.svg";
import { Event } from "../../utils/consts/event";

import "./Footer.scss";

const Footer: React.FC = (): React.ReactElement => (
  <div className="footer-container">
    <footer className="footer">
      <div className="footer-center">
        <div className="footer-text-container">
          <img src={chatIcon} alt="chat-icon" className="chat-icon" />
          <p>
            Have questions? Visit the{" "}
            <a href="/faqs" rel="noopener help">
              &apos;FAQ&apos;
            </a>{" "}
            page to learn more.
          </p>
        </div>
        <div className="footer-text-container">
          <img src={mailIcon} alt="mail-icon" className="mail-icon" />
          <p>
            Have feedback? Please share with{" "}
            <a
              href={`mailto: ${Event.contact.feedbackEmail}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {Event.contact.feedbackEmail}
            </a>
          </p>
        </div>
        <div className="footer-text-container">
          <img src={flagIcon} alt="flag-icon" className="flag-icon" />
          <p>
            Caught a bug? Report it to{" "}
            <a
              href={`mailto: ${Event.contact.reportBugEmail}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {Event.contact.reportBugEmail}
            </a>
          </p>
        </div>
      </div>
    </footer>
  </div>
);

export default Footer;
