import React, { FC } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import "./Dropdown.scss";

type Props = DropdownProps & {
  label: string;
};

const DropdownFilter: FC<Props> = (props) => (
  <div className="dropdown-container">
    {props.label && <div>{props.label}</div>}
    <Dropdown {...props} />
  </div>
);

DropdownFilter.propTypes = {
  label: PropTypes.string,
};

export default DropdownFilter;
