import { type ISourceOptions } from "@tsparticles/engine";
import confettiOrange from "../../assets/images/confetti/confetti-orange.svg";
import confettiPink from "../../assets/images/confetti/confetti-pink.svg";

export const confettiOptions: ISourceOptions = {
  key: "images",
  name: "Images",
  fullScreen: {
    zIndex: 1000,
  },
  emitters: [
    {
      position: {
        x: 0,
        y: 80,
      },
      rate: {
        quantity: 25,
        delay: 0.15,
      },
      spawnColor: {
        value: "#FF8000",
        animation: {
          h: {
            enable: true,
            offset: {
              min: -1.4,
              max: 1.4,
            },
            speed: 0.1,
            sync: false,
          },
          l: {
            enable: true,
            offset: {
              min: 20,
              max: 80,
            },
            speed: 0,
            sync: false,
          },
        },
      },
      life: {
        count: 1,
        duration: 2,
        delay: 0.6,
      },
      size: {
        width: 0,
        height: 0,
      },
    },
    {
      position: {
        x: 100,
        y: 80,
      },
      rate: {
        quantity: 25,
        delay: 0.15,
      },
      life: {
        count: 1,
        duration: 2,
        delay: 0.6,
      },
    },
  ],
  particles: {
    move: {
      decay: 0.05,
      direction: "top",
      enable: true,
      gravity: {
        enable: true,
      },
      outModes: {
        top: "none",
        default: "destroy",
      },
      speed: {
        min: 50,
        max: 100,
      },
    },
    number: {
      value: 0,
    },
    opacity: {
      value: 1,
    },
    rotate: {
      value: {
        min: 0,
        max: 360,
      },
      direction: "random",
      animation: {
        enable: true,
        speed: 30,
      },
    },
    tilt: {
      direction: "random",
      enable: true,
      value: {
        min: 0,
        max: 360,
      },
      animation: {
        enable: true,
        speed: 30,
      },
    },
    size: {
      value: 8,
      animation: {
        enable: true,
        startValue: "min",
        count: 1,
        speed: 16,
        sync: true,
      },
    },
    roll: {
      darken: {
        enable: true,
        value: 25,
      },
      enlighten: {
        enable: true,
        value: 25,
      },
      enable: true,
      speed: {
        min: 2,
        max: 5,
      },
    },
    wobble: {
      distance: 30,
      enable: true,
      speed: {
        min: -7,
        max: 7,
      },
    },
    shape: {
      type: "image",
      options: {
        image: [
          {
            name: "confetti-pink",
          },
          {
            name: "confetti-orange",
          },
        ],
      },
    },
  },
  preload: [
    {
      name: "confetti-pink",
      src: confettiPink,
      width: 94,
      height: 95,
    },
    {
      name: "confetti-orange",
      src: confettiOrange,
      width: 86,
      height: 86,
    },
  ],
};
