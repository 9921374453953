import React from "react";
import points from "../../assets/images/pages/scl_end_announcement.svg";
import Button from "../../components/Buttons/Button";
import { Event } from "../../utils/consts/event";
import "./WrapPage.scss";

const event = Event;
export const VIEW_RECORDINGS_LINK = event.wrapPage.viewRecordingLink;
export const FEEDBACK_LINK = event.wrapPage.feedbackEmailAddress;
export const SHAREPOINT_LINK = event.wrapPage.sharePointSiteLink;

interface WrapPageProps {
  text: string;
}

const renderButtonContainer = (): React.JSX.Element => (
  <div className="wrap-page-button-container">
    <Button
      style="secondary"
      classes={["wrap-page-button"]}
      type="button"
      value="VISIT OUR SHAREPOINT SITE"
      onClick={() => window.open(SHAREPOINT_LINK, "_blank")}
    />
    <Button
      style="secondary"
      classes={["wrap-page-button"]}
      type="button"
      value="VIEW RECORDINGS"
      onClick={() => window.open(VIEW_RECORDINGS_LINK, "_blank")}
    />
    <Button
      style="primary"
      classes={["wrap-page-button"]}
      type="button"
      value="GIVE FEEDBACK"
      onClick={() => window.open(FEEDBACK_LINK, "_blank")}
    />
  </div>
);

const renderBackgroundImg = (): React.JSX.Element => <></>;

const WrapPage = ({ text }: WrapPageProps): React.JSX.Element => {
  return (
    <div className="wrap-page-bg-container">
      {renderBackgroundImg()}
      <div className="wrap-page-container">
        <div className="wrap-page-image-container">
          <img src={points} alt={"wrap page banner"} className="wrap-image" />
        </div>
        <div className="wrap-page-text">
          <p>{text}</p>
        </div>
        {renderButtonContainer()}
      </div>
    </div>
  );
};

export default WrapPage;
