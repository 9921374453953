import { Auth } from "aws-amplify";
import jwtDecode from "jwt-decode";
import moment from "moment";
import {
  LOCAL_LOGIN_COMPLETE,
  LOCAL_USER_DATA,
} from "./utils/consts/amplifyKeys";

const AUTH_PROVIDER = "SlalomOIDC";
const keys = Object.keys(localStorage).filter(
  (k) =>
    k.startsWith("CognitoIdentityServiceProvider.") === true &&
    k.endsWith(".idToken") === true
);

type UserDataType = {
  jwtToken: any;
  userId: string;
  exp: number;
};

const signoutUser = (setUserData: (userData: UserDataType | null) => void) => {
  localStorage.removeItem(LOCAL_LOGIN_COMPLETE);
  setUserData(null);
  localStorage.removeItem(LOCAL_USER_DATA);
};

const signinUser = async (
  setUserData: (userData: UserDataType | null) => void
) => {
  try {
    // Make sure we have a current access token
    await Auth.currentSession();
    storeUser(setUserData);
  } catch (e) {
    await Auth.federatedSignIn({ customProvider: AUTH_PROVIDER });
    storeUser(setUserData);
  }
};

const storeUser = (
  setUserData: (userData: UserDataType | null) => void
): void => {
  const jwtToken = localStorage.getItem(keys[0]);
  const idTokenData: any = jwtDecode(String(jwtToken));
  const userId = String(idTokenData.identities[0]?.userId);
  const exp = Number(idTokenData.exp);
  const userData: UserDataType = {
    jwtToken: jwtToken,
    userId: userId,
    exp: exp,
  };
  setUserData(userData);
  localStorage.setItem(LOCAL_USER_DATA, JSON.stringify(userData));
};

export const handleUserAuth = (
  userData: UserDataType,
  setUserData: (userData: UserDataType | null) => void
): void => {
  if (!userData) {
    // Sign in attempt
    signinUser(setUserData);
  } else if (
    // If user session has expired, signout User
    userData?.exp &&
    moment.utc(userData.exp * 1000).isBefore(moment.utc())
  ) {
    signoutUser(setUserData);
    signinUser(setUserData);
  }
};
