import PropTypes from "prop-types";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { getEvent } from "../../ApiHelper";
import divider from "../../assets/images/events-divider.svg";
import globe from "../../assets/images/globe.svg";
import RightArrow from "../../assets/images/solid-triangle-right.svg";
import pointsIcon from "../../assets/images/trophy-black.svg";
import { Event } from "../../models/event.model";
import { Participant } from "../../models/participant.model";
import { EventActionButton } from "../Buttons/EventActionButton";
import Card from "../Card/Card";
import ContentLinkParser from "../ContentLinkParser/ContentLinkParser";
import "./EventCard.scss";
import {
  cleanUpName,
  getDay,
  getMonth,
  getParams,
  getTime,
  shortenContent,
} from "./EventCard.util";

interface EventCardProps extends Event {
  eventId: number;
  activityLikeId: number;
  isPastEvent: boolean;
  isOngoing: boolean;
  isLiked: boolean;
  speakerProfiles: Participant[];
  setSpeakerProfiles: React.Dispatch<React.SetStateAction<Participant[]>>;
  maxLength: number;
}

const parserParams = getParams;

const renderEventCard = ({
  startDateTime,
  endDateTime,
  title,
  points,
  content,
  hostMarket,
  displayDetails,
  setDisplayDetails,
  speakerProfiles,
  maxLength,
}) => (
  <div className="event-details">
    <div className="event-details__top-row">
      <div className="event-details__date">
        <div>
          {getMonth(startDateTime)} {getDay(startDateTime)}
        </div>
        <div>
          {getTime(startDateTime)} to {getTime(endDateTime)}
        </div>
      </div>
      <img src={divider} alt="divider" />
      <div className={"event-details__name"}>
        <span>{cleanUpName(title)}</span>
      </div>
    </div>
    <div className="event-details__second-row">
      <div className="event-points">
        <div style={{ marginRight: "12px" }}>
          <img
            src={pointsIcon}
            alt="event-card-points-icon"
            className="event-card-points-icon"
          />
        </div>
        <div className="event-points-value">{points} Points</div>
      </div>
      <div className="event-details__location">
        <img src={globe} alt="globe" className="event-card-points-icon" />{" "}
        <span className="event-details__location__market">{hostMarket}</span>
      </div>
    </div>
    <div className="event-content">
      {!displayDetails ? (
        <ContentLinkParser
          content={shortenContent(content, maxLength)}
          params={parserParams}
        />
      ) : (
        <ContentLinkParser content={content} params={parserParams} />
      )}
    </div>
    <div
      className="event-card-secondary-btn-wrapper"
      style={{ visibility: content.length > maxLength ? "visible" : "hidden" }}
    >
      <button
        className="app-button app-button-text"
        type="button"
        onClick={() => {
          setDisplayDetails(!displayDetails);
        }}
      >
        {!displayDetails ? "Continue Reading" : "Show Less"}
        <img src={RightArrow} className="event-card-right-arrow-icon" />
      </button>
    </div>
    <div className="event-details__speakers-container">
      {speakerProfiles &&
        speakerProfiles.map((speaker, index) => (
          <div key={index} className="event-details__speaker-container">
            <strong>{`${speaker.firstName} ${speaker.lastName}`}</strong>
            <div className="event-details__speaker-container__market">
              {speaker.market}
            </div>
            <a
              className="link"
              href={`mailto:${speaker.user}`}
              data-testid="contact-email"
            >
              {speaker.user}
            </a>
          </div>
        ))}
    </div>
  </div>
);

const EventCard = (props: EventCardProps): React.ReactElement => {
  const [displayDetails, setDisplayDetails] = useState(false);

  const { data: eventDetailData, isLoading: eventDetailIsLoading } = useQuery(
    ["eventDetail", props.eventId],
    getEvent,
    {
      enabled: props.eventId,
    }
  );
  let speakerProfiles = [];
  if (!eventDetailIsLoading) {
    speakerProfiles = eventDetailData?.speakerProfiles;
  }

  return (
    <Card
      containerClass={`event-card-container ${displayDetails ? "flipped" : ""}`}
    >
      {renderEventCard({
        ...props,
        displayDetails,
        setDisplayDetails,
        speakerProfiles,
      })}
      <EventActionButton
        url={props.signupLink}
        isPastEvent={props.isPastEvent}
        isOngoing={props.isOngoing}
        liked={props.isLiked}
        eventId={props.eventId}
        activityLikeId={props.activityLikeId}
      />
    </Card>
  );
};

EventCard.propTypes = {
  startDateTime: PropTypes.string,
  endDateTime: PropTypes.string,
  title: PropTypes.string,
  points: PropTypes.number,
  content: PropTypes.string,
  signupLink: PropTypes.string,
  speakers: PropTypes.array,
  eventId: PropTypes.number,
  activityLikeId: PropTypes.number,
  isPastEvent: PropTypes.bool,
  isOngoing: PropTypes.bool,
  isLiked: PropTypes.bool,
  maxLength: PropTypes.number,
  speakerProfiles: PropTypes.array,
};

renderEventCard.propTypes = {
  startDateTime: PropTypes.string,
  endDateTime: PropTypes.string,
  title: PropTypes.string,
  points: PropTypes.number,
  content: PropTypes.string,
  speakerProfiles: PropTypes.array,
  maxLength: PropTypes.number,
};

export default EventCard;
