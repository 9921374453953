import React, { useContext, useEffect, useRef, useState } from "react";
import { ParticipantContext } from "../../App";
import useDetectOutsideClick from "../../hooks/useDetectOutsideClick";
import { shouldShowWinners } from "../../utils/eventUtil";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";
import NavLogo from "../NavLogo/NavLogo";
import WelcomeModal from "../WelcomeModal/WelcomeModal";
import NavHamburgerMenu from "./NavHamurgerMenu/NavHamburgerMenu";
import NavOptions from "./NavOptions/NavOptions";
import NavUserAndPoints from "./NavUserAndPoints/NavUserAndPoints";
import "./TopNavigationBar.scss";

const { TABLET_MAX } = deviceWidthBreakpoints;

export enum ENavLink {
  Winners = "Winners",
  Events = "Events",
  Home = "Home",
  About = "About",
  Faqs = "FAQs",
}

const getNavLinks = () =>
  shouldShowWinners()
    ? [ENavLink.Home, ENavLink.About]
    : [ENavLink.Home, ENavLink.About, ENavLink.Events, ENavLink.Faqs];

const TopNavigationBar: React.FC = (): React.ReactElement => {
  const { width } = useWindowSize();
  const navLinks = getNavLinks();
  const participant = useContext(ParticipantContext);
  const [navMenuOpen, setNavMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const navRef = useRef();

  useDetectOutsideClick(navRef, () => setNavMenuOpen(false));

  useEffect(() => {
    if (participant?.firstVisit) {
      setShowModal(true);
    }
  }, [participant]);

  return (
    <header ref={navRef}>
      <nav className={`navigation-bar-container`}>
        <NavLogo width={width} />
        {width > TABLET_MAX && <NavOptions />}
        {width <= TABLET_MAX && (
          <NavHamburgerMenu
            width={width}
            participantData={participant}
            navLinks={navLinks}
            menuOpen={navMenuOpen}
            setMenuOpen={setNavMenuOpen}
          />
        )}
        <NavUserAndPoints
          width={width}
          participantData={participant}
          participantIsLoading={!participant}
        />
      </nav>
      <WelcomeModal show={showModal} setShow={setShowModal} />
    </header>
  );
};

export default TopNavigationBar;
