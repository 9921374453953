import React, { useState } from "react";
import { queryCache, useMutation, useQuery } from "react-query";
import { getQuiz, postQuizAnswer } from "../../ApiHelper";
import trophyIcon from "../../assets/images/trophy-purple.png";
import useWindowSize from "../../utils/useWindowSize";
import Button from "../Buttons/Button";
import Card from "../Card/Card";
import SectionHeader from "../SectionHeader/SectionHeader";
import "./QuizOfTheDay.scss";

const QuizOfTheDay: React.FC = () => {
  const { width } = useWindowSize();

  const { data: quizData } = useQuery("quiz", getQuiz, {
    refetchOnWindowFocus: false,
  });

  const [selectedAnswerId, setSelectedAnswerId] = useState<number>(null);

  const [submitQuizAnswer, { isLoading }] = useMutation(postQuizAnswer, {
    onSettled: () => {
      setSelectedAnswerId(null);
      queryCache.invalidateQueries("quiz");
      queryCache.invalidateQueries("participant");
      queryCache.invalidateQueries("participants");
    },
  });

  const handleSubmitAnswer = (): void => {
    if (!selectedAnswerId) {
      return;
    }

    submitQuizAnswer({
      quizId: quizData?.quizId,
      answerId: selectedAnswerId,
      user: "",
    });
  };

  // determines radio button highlighting based on userAnswer
  const getQuizAnswerClass = (currAnswerId: number) => {
    const className = "quiz-button-";
    if (quizData?.userAnswer) {
      const { correctAnswerId, answerId, isCorrect } = quizData.userAnswer;
      if (correctAnswerId === currAnswerId) {
        return `${className}correct`;
      } else if (!isCorrect && currAnswerId === answerId) {
        return `${className}wrong`;
      }
    } else if (currAnswerId === selectedAnswerId) {
      return `${className}selected`;
    }
    return "";
  };

  const getAnswerExplanation = () => {
    if (quizData?.userAnswer) {
      const { explanation, isCorrect } = quizData.userAnswer;
      const text = isCorrect
        ? "Correct!"
        : "Sorry! You got this one wrong. Join us again tomorrow to answer a new question for a chance to earn points!";
      return <p className="quiz-explanation">{`${text} ${explanation}`}</p>;
    }
  };

  return (
    <div className="section-container">
      <div className="section-qotd">
        <SectionHeader
          title={"Question of the Day"}
          width={width}
        ></SectionHeader>

        <div className="quiz-of-the-day">
          <Card containerClass="quiz-description">
            <div className="points-container">
              <img src={trophyIcon} alt="trophy-icon" className="trophy-icon" />
              {quizData?.points || "?"} points
            </div>
            <p>
              {`Showcase your Slalom City Limits and music knowledge by completing the Question of the Day! 
              Each business day, you will have the chance to answer two questions to win points. 
              The questions refresh every 12 hours, so don’t miss out!`}
            </p>
          </Card>
          <Card containerClass="quiz-content">
            <div className="quiz-content__section">
              <div className="quiz-content__title">Today&apos;s Question</div>
              <div className="quiz-question">{quizData?.description}</div>
            </div>
            <div className="quiz-answers-container">
              <div className="quiz-answers-row">
                {quizData?.answers
                  ?.sort((a, b) => a.order - b.order)
                  .map((quizAnswer) => (
                    <button
                      key={`quiz-button-${quizAnswer.quizAnswerId}`}
                      className={[
                        "quiz-button",
                        getQuizAnswerClass(quizAnswer.quizAnswerId),
                      ]
                        .filter(Boolean)
                        .join(" ")}
                      disabled={!!quizData?.userAnswer}
                      onClick={() =>
                        setSelectedAnswerId(quizAnswer.quizAnswerId)
                      }
                    >
                      <div className="quiz-button-answer">
                        {quizAnswer.description}
                      </div>
                      <div className="quiz-button-circle">
                        <div className="quiz-button-circle__inner"></div>
                      </div>
                    </button>
                  ))}
              </div>
              <br></br>
              {getAnswerExplanation()}
            </div>
            <div className={`quiz-submission-container`}>
              <div className="quiz-submission-content">
                <Button
                  type="button"
                  disabled={!!quizData?.userAnswer || !selectedAnswerId}
                  onClick={() => handleSubmitAnswer()}
                  value="Submit"
                  loading={isLoading}
                />
                {!quizData?.userAnswer && !selectedAnswerId && (
                  <p>Complete section to continue</p>
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default QuizOfTheDay;
