import PropTypes from "prop-types";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { getEvents } from "../../ApiHelper";
import eventsHeroLg from "../../assets/images/pages/Events_Desktop_1440.png";
import eventsHeroXl from "../../assets/images/pages/Events_Desktop_2560.png";
import eventsHeroSm from "../../assets/images/pages/Events_Mobile.png";
import eventsHeroMd from "../../assets/images/pages/Events_Tablet.png";
import LinkButton from "../../components/Buttons/LinkButton";
import Card from "../../components/Card/Card";
import ClaimPoints from "../../components/ClaimPoints/ClaimPoints";
import DropdownFilter from "../../components/Dropdown/Dropdown";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { TabOption, Tabs } from "../../components/Tabs/Tabs";
import {
  DayFilter,
  FilterItem,
  MarketFilter,
  TimeFilter,
  TopicFilter,
} from "../../models/filter.model";
import { Participant } from "../../models/participant.model";
import { Event } from "../../utils/consts/event";
import { EventStatus, getEventStatus } from "../../utils/eventUtil";
import { getBanner } from "../../utils/imageUtils";
import { getPageContainerClass } from "../../utils/responsiveClassHelpers";
import useWindowSize from "../../utils/useWindowSize";
import EventCardGrid from "./EventCardGrid";
import "./EventsPage.scss";
import {
  dayFilterOptions,
  EventsOptions,
  getCategoryOptionsFromEvents,
  getMarketFilterOptions,
  timeFilterOptions,
} from "./EventsPage.util";
import PastEventCardGrid from "./PastEventCardGrid";

export const VIEW_RECORDINGS_LINK = Event.wrapPage.viewRecordingLink;
const showWarning = false;

type EventsPageProps = {
  showClaimPoints?: boolean;
  isPrelaunch?: boolean;
};

const eventsOptions: TabOption[] = [
  { title: "Upcoming", type: EventsOptions.Upcoming },
  { title: "Past", type: EventsOptions.Past },
];

const EventsPage: React.FC<EventsPageProps> = ({
  showClaimPoints,
  isPrelaunch,
}): React.ReactElement => {
  const { width } = useWindowSize();
  const [selectedOption, setSelectedOption] = useState<EventsOptions>(
    EventsOptions.Upcoming
  );
  const [selectedMarkets, setSelectedMarkets] = useState<MarketFilter[]>([]);
  const [selectedTopics, setSelectedTopics] = useState<TopicFilter[]>([]);
  const [selectedDays, setSelectedDays] = useState<DayFilter[]>(["0"]);
  const [selectedTimes, setSelectedTimes] = useState<TimeFilter[]>(["all"]);
  const [speakerProfiles, setSpeakerProfiles] = useState<Participant[]>([]);

  const {
    data: eventsData,
    error: eventsError,
    isLoading: eventsAreLoading,
  } = useQuery(["events"], getEvents, { refetchOnWindowFocus: false });
  const marketFilterOptions: FilterItem<MarketFilter>[] =
    getMarketFilterOptions(eventsData);
  const topicFilterOptions: FilterItem<TopicFilter>[] =
    getCategoryOptionsFromEvents(eventsData);

  const banners = [eventsHeroXl, eventsHeroLg, eventsHeroMd, eventsHeroSm];

  return (
    <PageWrapper
      bannerImage={getBanner(width, banners)}
      isPrelaunch={isPrelaunch}
    >
      <div className={`app-page events-page__pink `}>
        <div className={getPageContainerClass(width)}>
          {showWarning && (
            <section className="temp-card-container">
              <Card>
                <div className="prelaunch-card-title">
                  <span role="img" aria-label="horn">
                    📣
                  </span>{" "}
                  Hang Tight!!!
                </div>
                <div className="temp-card-body">
                  <span className="bold">
                    Add to calendar functionality will be up shortly.
                  </span>{" "}
                  We apologize for the inconvenience. Check back soon!
                </div>
              </Card>
            </section>
          )}
          <div className="events-container">
            {getEventStatus() !== EventStatus.Prelaunch && <ClaimPoints />}
            <SectionHeader
              title={"Events"}
              width={width}
              tooltipContent={
                <p>
                  To RSVP for an event, click the &quot;Add to Calendar&quot;
                  button to recieve an invite.
                  <br></br> <br></br>
                  Loved the session you attended? Want to vote for an Artist
                  that inspired you? Be sure to give them Kudos on the events
                  page under the &quot;PAST&quot; tab.
                  <br></br> <br></br>
                  See a &quot;Join Now&quot; button? That means the event is
                  happening right now! Click that button to be taken directly to
                  that call.
                </p>
              }
            ></SectionHeader>
            <div className="app-card-display filter-container" id="tabs">
              <Tabs
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                tabOptions={eventsOptions}
              />
              <div className="filter-row">
                {selectedOption === "Past" && (
                  <div className="filter-entry">
                    <p className="dropdown-label previous-event-text">
                      View previous events
                    </p>
                    <div className={`recordings-button-container`}>
                      <LinkButton
                        text={"View recordings"}
                        href={VIEW_RECORDINGS_LINK}
                        newTab={true}
                      />
                    </div>
                  </div>
                )}
                <div className="filter-entry">
                  <p className="dropdown-label">Office Location</p>
                  <DropdownFilter
                    placeholder="Select office location"
                    onChange={(event, data) =>
                      setSelectedMarkets(data.value as MarketFilter[])
                    }
                    options={marketFilterOptions}
                    multiple
                    selection
                    className={`events-filter `}
                    label={""}
                  />
                </div>
                <div className="filter-entry">
                  <p className="dropdown-label">Topic</p>
                  <DropdownFilter
                    placeholder="Select topic"
                    onChange={(event, data) =>
                      setSelectedTopics(data.value as TopicFilter[])
                    }
                    options={topicFilterOptions}
                    multiple
                    selection
                    className={`events-filter `}
                    label={""}
                  />
                </div>
                <div className="filter-entry">
                  <p className="dropdown-label">Day</p>
                  <DropdownFilter
                    placeholder="Select day"
                    onChange={(event, data) =>
                      setSelectedDays(data.value as DayFilter[])
                    }
                    color="#deff4d"
                    options={dayFilterOptions}
                    multiple
                    selection
                    className={`events-filter `}
                    label={""}
                  />
                </div>

                <div className="filter-entry">
                  <p className="dropdown-label">Time</p>
                  <DropdownFilter
                    placeholder="Select time"
                    onChange={(event, data) => {
                      setSelectedTimes(data.value as TimeFilter[]);
                    }}
                    options={timeFilterOptions}
                    multiple
                    selection
                    className={`events-filter `}
                    label={""}
                  />
                </div>
              </div>
            </div>
            {selectedOption === EventsOptions.Upcoming ? (
              <div className="events-container">
                <EventCardGrid
                  eventsAreLoading={eventsAreLoading}
                  eventsError={eventsError as string}
                  eventsData={eventsData}
                  selectedTopics={selectedTopics}
                  selectedMarkets={selectedMarkets}
                  selectedDays={selectedDays}
                  selectedTimes={selectedTimes}
                  speakerProfiles={speakerProfiles}
                  setSpeakerProfiles={setSpeakerProfiles}
                />
              </div>
            ) : (
              <div className="events-container">
                <PastEventCardGrid
                  eventsAreLoading={eventsAreLoading}
                  eventsError={eventsError as string}
                  eventsData={eventsData}
                  selectedDays={selectedDays}
                  selectedMarkets={selectedMarkets}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

EventsPage.propTypes = {
  showClaimPoints: PropTypes.bool,
  isPrelaunch: PropTypes.bool,
};

export default EventsPage;
