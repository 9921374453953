import moment, { Moment } from "moment";
import { Event } from "../../models/event.model";
import {
  DayFilter,
  FilterItem,
  MarketFilter,
  TimeFilter,
  TopicFilter,
} from "../../models/filter.model";

export const workdayURL =
  "https://www.myworkday.com/slalom/d/inst/23455$84/rel-task/2998$35805.htmld ";

export const marketFilterOptions: FilterItem<MarketFilter>[] = [
  { key: "all", text: "All", value: "" },
  { key: "atlanta", text: "Atlanta", value: "Atlanta" },
  { key: "austin", text: "Austin", value: "Austin" },
  { key: "charlotte", text: "Charlotte", value: "Charlotte" },
  { key: "chicago", text: "Chicago", value: "Chicago" },
  { key: "dallas", text: "Dallas", value: "Dallas" },
  { key: "dc", text: "DC", value: "DC" },
  { key: "denver", text: "Denver", value: "Denver" },
  { key: "detroit", text: "Detroit", value: "Detroit" },
  { key: "houston", text: "Houston", value: "Houston" },
  { key: "minneapolis", text: "Minneapolis", value: "Minneapolis" },
  { key: "new jersey", text: "New Jersey", value: "New Jersey" },
  { key: "new york city", text: "New York City", value: "New York City" },
  { key: "norcal", text: "NorCal", value: "NorCal" },
  { key: "philadelphia", text: "Philadelphia", value: "Philadelphia" },
  { key: "phoenix", text: "Phoenix", value: "Phoenix" },
  { key: "portland", text: "Portland", value: "Portland" },
  { key: "seattle", text: "Seattle", value: "Seattle" },
  { key: "socal", text: "SoCal", value: "SoCal" },
  { key: "st. louis", text: "St. Louis", value: "St. Louis" },
  { key: "toronto", text: "Toronto", value: "Toronto" },
  { key: "vancouver", text: "Vancouver", value: "Vancouver" },
];

export const dayFilterOptions: FilterItem<DayFilter>[] = [
  { key: "all", text: "All", value: "0" },
  { key: "monday", text: "Monday", value: "1" },
  { key: "tuesday", text: "Tuesday", value: "2" },
  { key: "wednesday", text: "Wednesday", value: "3" },
  { key: "thursday", text: "Thursday", value: "4" },
  { key: "friday", text: "Friday", value: "5" },
];

export const timeFilterOptions: FilterItem<TimeFilter>[] = [
  { key: "all", text: "All", value: "all" },
  { key: "morning", text: "Morning", value: "morning" },
  { key: "afternoon", text: "Afternoon", value: "afternoon" },
];

export enum EventsOptions {
  Upcoming = "Upcoming",
  Past = "Past",
}

export enum RelativeTime {
  FUTURE = "future",
  PAST = "past",
}

/**
 * Returns an array of unique categories from the given `events` array as an
 * array of `DropdownItemProps` objects. Categories with an empty or falsy
 * `event.categoryName` value are excluded.
 * @param {[]} events - An array of event objects.
 * @returns {FilterItem<TopicFilter>[]} An array of unique categories as `DropdownItemProps` objects.
 */
export function getCategoryOptionsFromEvents(
  events: Event[]
): FilterItem<TopicFilter>[] {
  const categoriesOptionsBase: FilterItem<TopicFilter>[] = [
    {
      key: "all",
      text: "All",
      value: "all",
    },
  ];
  let categoriesOptionsFromEvents: FilterItem<TopicFilter>[] = [];
  if (events) {
    const categories: string[] = Array.from(
      new Set(
        events
          .map((event) => event.categoryName)
          .filter(Boolean)
          .sort()
      )
    );
    categoriesOptionsFromEvents = categories.map((category) => ({
      key: category,
      text: category,
      value: category as TopicFilter,
    }));
  }
  return [...categoriesOptionsBase, ...categoriesOptionsFromEvents];
}

export const getMarketFilterOptions = (
  eventData: Event[]
): FilterItem<MarketFilter>[] => {
  if (!eventData?.length) {
    return [];
  }

  return Array.from(
    new Set(eventData.map((event) => event.hostMarket).filter(Boolean))
  ).map((market) => ({
    key: market,
    text: market,
    value: market,
  }));
};

const stripDate = (momentStr: string): Moment =>
  moment(moment(momentStr).format("LT"), "HH:mm A");

const isMorning = (momentStr: string): boolean =>
  stripDate(momentStr).isBefore(moment("12:00", "HH:mm A"));

export const filterTopicEvents = (
  event: Event,
  selectedTopics: string[]
): boolean =>
  selectedTopics.includes("all") || !selectedTopics.length
    ? true
    : "categoryName" in event &&
      selectedTopics.includes(event.categoryName.toString());

export const filterMarketEvents = (
  event: Event,
  selectedMarkets: MarketFilter[]
): boolean =>
  selectedMarkets.includes("") || !selectedMarkets.length
    ? true
    : selectedMarkets.includes(event.hostMarket.toString() as MarketFilter);

export const filterDayEvents = (
  event: Event,
  selectedDays: DayFilter[]
): boolean =>
  selectedDays.includes("0") || !selectedDays.length
    ? true
    : selectedDays.includes(
        moment(event.startDateTime).day().toString() as DayFilter
      );

export const filterTimeEvents = (
  event: Event,
  selectedTimes: TimeFilter[]
): boolean => {
  if (
    selectedTimes.includes("all") ||
    !selectedTimes.length ||
    (selectedTimes.includes("morning") && selectedTimes.includes("afternoon"))
  ) {
    return true;
  }
  if (selectedTimes.includes("morning")) {
    return isMorning(event.startDateTime) || isMorning(event.endDateTime);
  }
  if (selectedTimes.includes("afternoon")) {
    return !isMorning(event.startDateTime) || !isMorning(event.endDateTime);
  }
};

export const filterEvents = (
  eventsData: Event[],
  selectedTopics: string[],
  selectedMarkets: MarketFilter[],
  selectedDays: DayFilter[],
  selectedTimes: TimeFilter[]
): Event[] =>
  eventsData?.filter
    ? eventsData
        .filter((event) => {
          const filters = ["days", "times", "markets", "topics"];
          return filters.every((key) =>
            key === "days"
              ? filterDayEvents(event, selectedDays)
              : key === "times"
              ? filterTimeEvents(event, selectedTimes)
              : key === "markets"
              ? filterMarketEvents(event, selectedMarkets)
              : filterTopicEvents(event, selectedTopics)
          );
        })
        .sort((a, b) => moment(a.startDateTime).diff(moment(b.startDateTime)))
    : [];
