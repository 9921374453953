import React from "react";
import thumbsup from "../../assets/images/thumbs-up-purple.png";
import useWindowSize from "../../utils/useWindowSize";
import LinkButton from "../Buttons/LinkButton";
import Card from "../Card/Card";
import SectionHeader from "../SectionHeader/SectionHeader";
import "./Donate.scss";

const Donate: React.FC = () => {
  const { width } = useWindowSize();

  return (
    <div className="donate-section-container">
      <SectionHeader
        title={"Don't forget to Give Kudos!"}
        width={width}
      ></SectionHeader>
      <Card containerClass="donate-description">
        <div className="donate-container">
          <img src={thumbsup} alt="thumbsup-icon" className="thumbsup-icon" />
          Thank you
        </div>
        <div className="donate-text-container">
          <p>
            Loved the session you attended? Want to vote for an Artist that
            inspired you? Be sure to give them Kudos on the Events page under
            the <b>&quot;PAST&quot; tab</b>!
          </p>
        </div>
        <div className="app-button-container">
          <LinkButton
            text="Go Give Kudos"
            style="primary"
            href="/events/#tabs"
            newTab={false}
          />
        </div>
      </Card>
    </div>
  );
};

export default Donate;
