import React from "react";
import SecuryetiImg from "../../assets/images/securyeti.png";
import "./Securyeti.scss";

const Securyeti: React.FC = (): React.ReactElement => (
  <div className="securyeti-container">
    <img src={SecuryetiImg} alt="" />
    <p>
      Congrats! You found Securyeti. Thanks for keeping Slalom safe! Email a
      screenshot to <a href="mailto:">your_email_here</a> to claim your points.
    </p>
  </div>
);

export default Securyeti;
