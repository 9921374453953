import { deviceWidthBreakpoints } from "./useWindowSize";

const { MOBILE_MAX, TABLET_MAX, LAPTOP_MAX } = deviceWidthBreakpoints;

export const isDesktop = (width) => width > TABLET_MAX;

export const isTablet = (width) => width <= TABLET_MAX && width > MOBILE_MAX;

export const isMobile = (width) => width <= MOBILE_MAX;

export const getResponsiveBackgroundClass = (width) =>
  `page-${width > MOBILE_MAX ? "large" : "mobile"}`;

export const getMainContainerClass = (width) =>
  `main-container-${
    width > TABLET_MAX ? "large" : width > MOBILE_MAX ? "tablet" : "mobile"
  }`;

export const getPageContainerClass = (width) =>
  `page-container-${width > TABLET_MAX ? "large" : "mobile"}`;

export const getTitleResponsiveClassName = (width) =>
  `title-text-${width > TABLET_MAX ? "large" : "mobile"}`;

export const getResponsiveClassName = (width) => {
  if (isDesktop(width)) {
    return "desktop";
  } else if (isTablet(width)) {
    return "tablet";
  } else {
    return "mobile";
  }
};

export const getBreakpointFromWidth = (width) => {
  if (width === null || width === undefined) {
    return LAPTOP_MAX;
  }

  if (width <= MOBILE_MAX) {
    return MOBILE_MAX;
  } else if (width <= TABLET_MAX) {
    return TABLET_MAX;
  }

  return LAPTOP_MAX;
};
