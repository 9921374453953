import React from "react";
import { Market } from "../../models/market.model";
import { ParticipantRank } from "../../models/participant.model";
import { deviceWidthBreakpoints } from "../../utils/useWindowSize";
import { LeaderBoardOptions } from "../Leaderboard/Leaderboard.interface";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import TopLeaderCard from "../TopLeaderCard/TopLeaderCard";

const { TABLET_MAX } = deviceWidthBreakpoints;

const topLeadersResponsiveClassName = (width) =>
  `leaders ${width < TABLET_MAX ? "leaders-mobile" : ""}`;

type LoadLeadersProps = {
  width: any;
  data: ParticipantRank[] | Market[];
  selectedOption: LeaderBoardOptions;
  error: any;
  isLoading: boolean;
};

const TopLeaders: React.FC<LoadLeadersProps> = ({
  width,
  data,
  selectedOption,
  error,
  isLoading,
}: LoadLeadersProps) => {
  const isMarket = selectedOption === "Market";
  return (
    <div className="leaders">
      <LoadingSpinner
        isLoading={isLoading}
        error={error}
        color={"#0c62fb"}
        size={"massive"}
      >
        {data &&
          data
            .slice(0, 3)
            .map((_data: ParticipantRank | Market, idx) => (
              <TopLeaderCard
                data={_data}
                key={`${
                  isMarket
                    ? (_data as Market).name
                    : (_data as ParticipantRank).user
                }-${idx}`}
                type={selectedOption}
                position={idx}
              />
            ))}
      </LoadingSpinner>
    </div>
  );
};

export default TopLeaders;
