import { ProgramMember } from "../../models/programMembers.model";
import useWindowSize from "../../utils/useWindowSize";
import TeamInfoTile from "../TeamInfoTile/TeamInfoTile";
import "./AboutInfoList.scss";

type AboutInfoListProps = {
  participants: ProgramMember[];
};
const renderAboutList = (participants: any): JSX.Element => {
  return participants.map((participant, index) => (
    <TeamInfoTile allowFlipTile={false} key={index} participant={participant} />
  ));
};

const aboutInfoListResponsive = (width: number) => {
  return `about-list-component`;
};

const AboutInfoList = ({ participants }: AboutInfoListProps) => {
  const { width } = useWindowSize();
  return (
    <div className={`${aboutInfoListResponsive(width)}`}>
      {renderAboutList(participants)}
    </div>
  );
};

export default AboutInfoList;
