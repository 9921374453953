import React from "react";
import "./Card.scss";
import Tooltip from "../Tooltip/Tooltip";

interface CardProps {
  children?: any;
  tooltipContent?: any;
  title?: string;
  containerStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  containerClass?: string;
}

// Generic Card component - to override styles, pass props for container and/or content
const Card: React.FC<CardProps> = ({
  children,
  tooltipContent,
  title,
  containerStyle,
  contentStyle,
  containerClass = "",
}): React.ReactElement => {
  const containerClasses = ["app-card", containerClass]
    .filter((c) => Boolean(c))
    .join(" ");
  return (
    <div className={containerClasses} style={containerStyle}>
      {(title || tooltipContent) && (
        <div className="app-card-header">
          {title && <h2 className="app-card-title">{title}</h2>}
          {tooltipContent && <Tooltip>{tooltipContent}</Tooltip>}
        </div>
      )}
      <div className="app-card-content" style={contentStyle}>
        {children}
      </div>
    </div>
  );
};

export default Card;
