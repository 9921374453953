import { ProgramMember } from "../../models/programMembers.model";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";
import "./TeamInfoTile.scss";

const { TABLET_MAX } = deviceWidthBreakpoints;

interface TeamInfoTileProps {
  participant: ProgramMember;
  allowFlipTile: boolean;
}
const TeamInfoTile = ({ participant, allowFlipTile }: TeamInfoTileProps) => {
  const { width } = useWindowSize();

  const renderProfileTile = ({
    firstName,
    lastName,
    programMemberPronounTypeName: memberPronouns,
    programMemberRoleTypeDomain: memberRole,
    market,
  }: ProgramMember): JSX.Element => {
    return width > TABLET_MAX ? (
      <div className="team-profile-front-tile-container">
        <div className="team-profile-name-container">{`${firstName} ${lastName}`}</div>
        <div className="team-profile-role-container">{`${memberRole[0].programMemberRoleTypeName} | ${market}`}</div>
        <div>{memberPronouns}</div>
      </div>
    ) : (
      <div className="team-profile-front-tile-container">
        <div className="team-profile-name-container">{firstName}</div>
        <div className="team-profile-name-container">{lastName}</div>
        <div className="team-profile-role-container">{market}</div>
      </div>
    );
  };

  const { image } = participant;

  const imgUrl = `data:${image.contentType};${image.encoding},${image.data}`;
  return (
    <div
      className="team-profile-tile-container profile-tile-margin"
      data-testid="team-profile-front-tile-container"
    >
      <img src={imgUrl} className="team-profile-tile-image" />

      <div className="about-info-container">
        {renderProfileTile(participant)}
      </div>
    </div>
  );
};

export default TeamInfoTile;
