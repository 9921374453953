import SplashDesktop from "../../assets/images/Splash_Desktop_L.png";
import SplashMobile from "../../assets/images/Splash_Mobile.png";
import SplashTablet from "../../assets/images/Splash_Tablet.png";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";
import "./SplashPage.scss";

const { TABLET_MAX, MOBILE_MAX } = deviceWidthBreakpoints;

const renderBackgroundMedia = (width: number) =>
  width > TABLET_MAX
    ? renderDesktopBackgroundImg(width)
    : width > MOBILE_MAX
    ? renderTabletBackgroundImg(width)
    : renderMobileBackgroundImg(width);

const renderDesktopBackgroundImg = (width): JSX.Element => (
  <img
    src={SplashDesktop}
    alt="Desktop Splash Page displaying Slalom City Limits title."
    className="splash-background-image__desktop"
  ></img>
);
const renderTabletBackgroundImg = (width): JSX.Element => (
  <img
    src={SplashTablet}
    alt="Tablet Splash Page displaying displaying Slalom City Limits title."
    className="splash-background-image"
  ></img>
);

const renderMobileBackgroundImg = (width): JSX.Element => (
  <img
    src={SplashMobile}
    alt="Mobile Splash Page displaying displaying Slalom City Limits title."
    className="splash-background-image"
  ></img>
);

const SplashPage = () => {
  const { width } = useWindowSize();

  return (
    <main className="splash-container">{renderBackgroundMedia(width)}</main>
  );
};

export default SplashPage;
