import React, { SetStateAction, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getMarkets, getParticipants } from "../../ApiHelper";
import { Market } from "../../models/market.model";
import {
  ParticipantRank,
  ParticipantRole,
} from "../../models/participant.model";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";
import Card from "../Card/Card";
import DropdownFilter from "../Dropdown/Dropdown";
import SectionHeader from "../SectionHeader/SectionHeader";
import Securyeti from "../Securyeti/Securyeti";
import { TabOption, Tabs } from "../Tabs/Tabs";
import Tooltip from "../Tooltip/Tooltip";
import { LeaderBoardOptions, LeaderboardProps } from "./Leaderboard.interface";
import "./Leaderboard.scss";
import { sortRankedParticipants } from "./Leaderboard.util";
import LeaderboardOrFallback from "./components/LeaderboardOrFallback";

const { MOBILE_MAX } = deviceWidthBreakpoints;

const role: ParticipantRole = {
  roleId: 1,
  name: "Tester",
};

// Manually update the Top 3 Artists Winners below
export const artistsWinners: ParticipantRank[] = [
  {
    user: "Jaren30@hotmail.com",
    firstName: "Barney",
    lastName: "Heaney",
    market: "Chicago",
    image: null,
    totalPoints: 100,
    marketRank: 1,
    marketTotal: 11,
    globalRank: 1,
    globalTotal: 33,
    role,
    firstVisit: false,
  },
  {
    user: "Evangeline.Trantow@hotmail.com",
    firstName: "Jordon",
    lastName: "Cruickshank",
    market: "Chicago",
    image: null,
    totalPoints: 90,
    marketRank: 2,
    marketTotal: 11,
    globalRank: 6,
    globalTotal: 33,
    role,
    firstVisit: false,
  },
  {
    user: "Rudy52@yahoo.com",
    firstName: "Christy",
    lastName: "Hane",
    market: "Chicago",
    image: null,
    totalPoints: 80,
    marketRank: 3,
    marketTotal: 11,
    globalRank: 9,
    globalTotal: 33,
    role,
    firstVisit: false,
  },
];

const getLeaderboardOptions = (isWinnersLeaderboard: boolean): TabOption[] => {
  return isWinnersLeaderboard
    ? [
        { title: "Artists", type: "Local" },
        { title: "Fans", type: "Global" },
        { title: "Office", type: "Market" },
      ]
    : [
        { title: "Local", type: "Local" },
        { title: "Office", type: "Market" },
        { title: "Global", type: "Global" },
      ];
};

const sectionTitleString = (isLocalLeaderBoard) =>
  isLocalLeaderBoard ? "Local Winners" : "Participant Leaderboard";

const renderMobileLeaderBoardOptions = (
  width: number,
  setSelectedOption: React.Dispatch<SetStateAction<LeaderBoardOptions>>,
  selectedOption: LeaderBoardOptions,
  isWinnersLeaderBoard: boolean
) => {
  const leaderboardDropdownOptions: {
    text: string;
    value: LeaderBoardOptions;
  }[] = isWinnersLeaderBoard
    ? [
        { text: "Artists", value: "Local" },
        { text: "Fans", value: "Global" },
        { text: "Office", value: "Market" },
      ]
    : [
        { text: "Global", value: "Global" },
        { text: "Office", value: "Market" },
        { text: "Local", value: "Local" },
      ];

  return width <= MOBILE_MAX ? (
    <div className="leaderboard-options-mobile">
      <DropdownFilter
        placeholder="Global"
        defaultValue={selectedOption}
        onChange={(event, data) =>
          setSelectedOption(data.value as LeaderBoardOptions)
        }
        options={leaderboardDropdownOptions}
        selection
        label=""
      />
    </div>
  ) : null;
};

const Leaderboard: React.FC<LeaderboardProps> = ({
  isLocalLeaderBoard = false,
  isWinnersLeaderBoard,
  participantData,
}: LeaderboardProps) => {
  const { width } = useWindowSize();
  const [selectedOption, setSelectedOption] =
    useState<LeaderBoardOptions>("Local");

  const {
    data: globalData,
    error: globalError,
    isLoading: globalIsLoading,
  } = useQuery(["global", "Global"], getParticipants, {
    refetchOnWindowFocus: false,
  });

  const {
    data: localData,
    error: localError,
    isLoading: localIsLoading,
  } = useQuery(["local", participantData?.market], getParticipants, {
    refetchOnWindowFocus: false,
  });

  const {
    data: marketsData,
    error: marketsError,
    isLoading: marketsIsLoading,
  } = useQuery(["markets"], getMarkets, { refetchOnWindowFocus: false });

  let participants: ParticipantRank[] = null;
  let markets: Market[] = null;
  let error = null;
  let isLoading = null;
  if (selectedOption === "Global") {
    participants = globalData;
    error = globalError;
    isLoading = globalIsLoading;
  } else if (selectedOption === "Market") {
    markets = marketsData;
    error = marketsError;
    isLoading = marketsIsLoading;
  } else if (selectedOption === "Local") {
    participants = isWinnersLeaderBoard ? artistsWinners : localData;
    error = localError;
    isLoading = localIsLoading;
  }

  const sortedParticipants =
    participants && sortRankedParticipants(participants);

  return (
    <div className="leaderboard-section section-container">
      <div className="leaderboard-tooltip">
        <SectionHeader
          title={sectionTitleString(isLocalLeaderBoard)}
          width={width}
        ></SectionHeader>
        <Tooltip>
          This leaderboard updates in real time. To learn more, check out the{" "}
          <Link to="/faqs">FAQ page</Link>.
        </Tooltip>
      </div>
      <Securyeti />

      <Card>
        <div className={`leaderboard-container ${selectedOption}-container`}>
          {width > MOBILE_MAX ? (
            <Tabs
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              tabOptions={getLeaderboardOptions(isWinnersLeaderBoard)}
            />
          ) : (
            renderMobileLeaderBoardOptions(
              width,
              setSelectedOption,
              selectedOption,
              isWinnersLeaderBoard
            )
          )}
          <LeaderboardOrFallback
            selectedOption={selectedOption}
            isLocalLeaderBoard={isLocalLeaderBoard}
            isWinnersLeaderBoard={isWinnersLeaderBoard}
            data={sortedParticipants || markets}
            isLoading={isLoading}
            error={error}
          />
        </div>
      </Card>
    </div>
  );
};

export default Leaderboard;
