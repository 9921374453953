import PropTypes from "prop-types";
import { useRef, useState } from "react";
import CloseIcon from "../../assets/images/tooltip_close.svg";
import InfoIcon from "../../assets/images/tooltip_info.svg";
import useDetectOutsideClick from "../../hooks/useDetectOutsideClick";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";
import "./Tooltip.scss";

const { TABLET_MAX } = deviceWidthBreakpoints;
const Tooltip = ({ children }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const { width } = useWindowSize();
  const tooltipContentRef = useRef(null);
  useDetectOutsideClick(tooltipContentRef, () => setTooltipVisible(false));

  const tooltipIconSize = width <= TABLET_MAX ? 25 : 35;

  return (
    <div className="tooltip-container">
      <div className="tooltip-icon">
        <img
          className="tooltip-icon__circle"
          onClick={() => setTooltipVisible(true)}
          src={InfoIcon}
          width={tooltipIconSize}
          height={tooltipIconSize}
          alt="tooltip icon"
        />
      </div>
      {tooltipVisible && (
        <div ref={tooltipContentRef} className="tooltip-popup">
          <div className="tooltip-popup__content">{children}</div>
          <div className="tooltip-popup__close">
            <img
              onClick={() => setTooltipVisible(false)}
              src={CloseIcon}
              alt="icon close"
            />
          </div>
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.any,
};

export default Tooltip;
