import DEFAULT_COVER from "../../assets/images/about-profile-placeholder.jpg";
import partnerLogo from "../../assets/images/partners/microsoft.png";
import { Markets } from "./markets";
import { Roles } from "./roles";
import { Titles } from "./titles";

export const Event = {
  eventName: "Slalom City Limits",
  eventDescription: "[Some Event Details Here .........]",
  additionalDetails: "[Add Event Additional Details Here ........]",
  startDate: "July 15",
  endDate: "July 18",
  nextYear: 2025,
  //announcements take the form { id: string, text: string }
  announcements: [],
  timeLineAndPrizes: [
    {
      title: "timeline-title-1",
      description: "timeline and prizes description - 1",
    },
    {
      title: "timeline-title-2",
      description: "timeline and prizes description - 2",
    },
    {
      title: "timeline-title-3",
      description: "timeline and prizes description - 3",
    },
    {
      title: "timeline-title-4",
      description: "timeline and prizes description - 4",
    },
  ],
  faqSections: [
    {
      name: "About",
      sectionData: [
        {
          title: "What is Slalom City Limits?",
          body: [
            {
              itemText:
                "Slalom City Limits is our Virtual Festival of Learning and Teaching! During this week-long event, we give our Artists the spotlight to share their expertise & contribute to our Culture of Learning & Teaching.",
            },
          ],
        },
        {
          title: "What is this platform?",
          body: [
            {
              itemText:
                "This platform may look familiar to you if you participated in events utilizing the Events Platform last year, or Hacktober. Our events platform blends cybersecurity with Slalom events to bring a fun, unique way to interact with our ERG and ID&E groups!",
            },
          ],
        },
        {
          title:
            "I need accommodation due to neurodiversity, visual & audio impairment, etc. What should I do?",
          body: [
            {
              itemText:
                "Please email zenith@slalom.com and include the accommodations you need to enable your participation.",
            },
          ],
        },
        {
          title: "What is an 'Artist'?",
          body: [
            {
              itemText:
                "An Artist is an expert who is passionate about a topic and wants to share their knowledge to Grow Experts & Leaders at Slalom. Artists teach a topic, share a client story, or lead a panel discussion.",
            },
          ],
        },
        {
          title: "What if I can't attend? Will the sessions be recorded?",
          body: [
            {
              itemText:
                "Every session will be recorded and uploaded into Workday. You will be able to find the link to all Slalom City Limits recordings on our Events Platform on the Events tab during the festival, and on the Slalom City Limits SharePoint page after the festival. Please note that event recordings are entered manually at the end of each day.",
            },
          ],
        },
        {
          title: "How can I get involved with the festival?",
          body: [
            {
              itemText:
                "Artist submissions are closed at this time, but you can get involved by supporting your peers and attending the live sessions! You can also give kudos, test your knowledge with the daily quizzes, and watch the recordings in Workday.",
            },
          ],
        },
      ],
    },
    {
      name: "Activities",
      sectionData: [
        {
          title: "What is a Question of the Day?",
          body: [
            {
              itemText:
                "The Question of the Day is a daily quiz during Slalom City Limits that, if answered correctly, will earn you points. The questions refresh every 12 hours, which is unique to this event, so be sure to check back every business day for a chance to earn points!",
            },
          ],
        },
        {
          title: "Are all the events global and open to everyone?",
          body: [
            {
              itemText:
                "Yes -- all events are virtual and the time for each event is reflected in your time zone!",
            },
          ],
        },
        {
          title: "How do I sign up for an event?",
          body: [
            {
              itemText:
                "Review the events calendar on the Events page and select “Add to Calendar” for the events you would like to attend. If you see a “Join Now” button instead of the “Add to Calendar” button, that means that event is currently taking place. Click that button to be taken directly to that call! If you have any issues, please email slalomcitylimits@slalom.com.",
            },
          ],
        },
      ],
    },
    {
      name: "Site",
      sectionData: [
        {
          title: "Where can I go to find Event Recordings?",
          body: [
            {
              itemText:
                "All recordings will be posted via the “View Recordings” button on the Events page. Please note that event recordings are entered manually, and may take up to 4 business days to be uploaded to the playlist.",
            },
          ],
        },
        {
          title:
            "Can I invite clients or people external to Slalom to this event?",
          body: [
            {
              itemText:
                "No, please do not share any meetings or events externally! The Zenith Platform is built exclusively for Slalom employees.",
            },
          ],
        },
        {
          title:
            "If I watch an event recording, can I still use the code to earn points?",
          body: [
            {
              itemText:
                "Yes -- event codes will only work until the end of Slalom City Limits however, so please be sure to input your code by then.",
            },
          ],
        },
        {
          title: "Where can I learn more about the artists and support staff?",
          body: [
            {
              itemText: "Head to the About page for details.",
            },
          ],
        },
      ],
    },
    {
      name: "Points & prizes",
      sectionData: [
        {
          title: "How do you earn points for Slalom City Limits?",
          body: [
            {
              itemText: `There are many ways to earn points. You can earn points by answering the Question of the Day, attending events, and completing activities listed under Other Ways to Earn Points.`,
            },
          ],
        },
        {
          title:
            "Who do I contact if I think my point total is tallied incorrectly?",
          body: [
            {
              itemText: `If you feel that you have an incorrect point total, please reach out to zenith@slalom.com and our development team will investigate! Please note that the "other ways to earn points" section has points added manually, so we appreciate your patience as those get added.`,
            },
          ],
        },
        {
          title: "What prizes are available?",
          body: [
            {
              itemText: `At the end of the month, the Securyeti will tally all the points acquired and award prizes for the following categories: Top 3 Fans, Top 3 Artists, and Top Market.`,
            },
          ],
        },
        {
          title: "When will winners be announced?",
          body: [
            {
              itemText:
                "Winners will be announced by August 16th. Please look out for an email if you've won! ",
            },
          ],
        },
      ],
    },
    {
      name: "Contact",
      sectionData: [
        {
          title: "Who can I contact if I am having issues with the site?",
          body: [
            {
              itemText:
                "Please email zenith@slalom.com with screenshots and a thorough description of the issue, and someone from our team will work to troubleshoot.",
            },
          ],
        },
        {
          title: "I have a question about an event. Who can I contact?",
          body: [
            {
              itemText:
                "Our team would be happy to help. Please email slalomcitylimits@slalom.com.",
            },
          ],
        },
        {
          title: "Who can I contact regarding Slalom City Limits questions?",
          body: [
            {
              itemText: "slalomcitylimits@slalom.com.",
            },
          ],
        },
        {
          title:
            "Who developed this events platform, and how can I get involved?",
          body: [
            {
              itemText:
                "A wonderful team of volunteers brought this platform to life. You can check them out by visiting the About page. Interested in participating in the next events platform build? Sign up via the about page!",
            },
          ],
        },
        {
          title:
            "I'd love to use the Event Platform for the work I'm doing. Who can I reach out to?",
          body: [
            {
              itemText:
                "Please sign up for our waitlist via our Microsoft Form microsoft-form-here.",
            },
          ],
        },
      ],
    },
  ],
  partnersList: [
    {
      name: "Microsoft",
      link: "https://twodegrees1.sharepoint.com/teams/GlobalAlliances/SitePages/Microsoft.aspx",
      image: partnerLogo,
    },
  ],
  aboutPage: {
    eventIntroText: `Slalom City Limits first took the stage in 2016 and brought employees together to teach, learn and have fun. 
    Seven years later, Slalom City Limits, in partnership with InfoSec and Zenith, has grown into an international festival inspiring Slalomers across the globe as we continue 
    to drive an inclusive learning culture. Every year, our goal is to live the Slalom core values of 
    tay "Humble and Curious" and "Celebrate Authenticity". Everyone plays a part in creating an amazing show so 
    thank you to the artists, producers, project team, and fans! `,
    aboutEventsPlatformText: `Information Security and Governance is proud to unveil the first ever Slalom Events Platform, 
    dedicated to bringing Slalom together in fun, secure, and meaningful ways! The Events Platform first began as Hacktober in 2019. 
    Created by Reedhima Mandlik, Hacktober is a way to make cybersecurity engaging and fun. Hacktober has since grown to be a Slalom 
    Women-led initiative in partnership with the Information Security and Governance team, and evolved into the Events Platform to help 
    connect cybersecurity and Slalom Events at a global scale. Our mission is to weave cybersecurity into the fabric of Slalom's culture 
    and help cultivate a security-first mindset. Together, we are all a vital part of the InfoSec team!`,
    aboutEventsFeedbackText: `We always welcome feedback on our platform or the events we are hosting! Feel free to contact us.`,
    aboutEventsJoinText: `Like what you see? Want experience building a custom platform in React? We welcome team members from every capability. Sign up via our link below!`,
    aboutEventsBugText: `Please report all bugs through our link below!`,
    aboutTeams: {
      Leadership: [
        {
          fullName: "Person McPerson",
          role: `${Roles.leadership.eventsPlatformLead}/${Roles.leadership.founder}`,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/about-profile-placeholder.jpg"),
          secondaryPhoto: DEFAULT_COVER,
        },
        {
          fullName: "Person McPerson",
          role: Titles.specialEvents,
          market: Markets.detroit,
          professionalPhoto: require("../../assets/images/about-profile-placeholder.jpg"),
          secondaryPhoto: DEFAULT_COVER,
        },
        {
          fullName: "Person McPerson",
          role: Roles.leadership.isg,
          market: Markets.seattle,
          professionalPhoto: require("../../assets/images/about-profile-placeholder.jpg"),
          secondaryPhoto: DEFAULT_COVER,
        },
      ],
      Design: [
        {
          fullName: "Person McPerson",
          role: Roles.xd,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/about-profile-placeholder.jpg"),
          secondaryPhoto: DEFAULT_COVER,
        },
        {
          fullName: "Person McPerson",
          role: Roles.xd,
          market: Markets.atlanta,
          professionalPhoto: require("../../assets/images/about-profile-placeholder.jpg"),
          secondaryPhoto: DEFAULT_COVER,
        },
        {
          fullName: "Person McPerson",
          role: Roles.xd,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/about-profile-placeholder.jpg"),
          secondaryPhoto: DEFAULT_COVER,
        },
      ],
      Development: [
        {
          fullName: "Person McPerson",
          role: Roles.solutionOwner,
          market: Markets.charlotte,
          professionalPhoto: require("../../assets/images/about-profile-placeholder.jpg"),
          secondaryPhoto: DEFAULT_COVER,
        },
        {
          fullName: "Person McPerson",
          role: Roles.smeArchitect,
          market: Markets.seattle,
          professionalPhoto: require("../../assets/images/about-profile-placeholder.jpg"),
          secondaryPhoto: DEFAULT_COVER,
        },
        {
          fullName: "Person McPerson",
          role: Roles.dataAnalytics,
          market: Markets.minneapolis,
          professionalPhoto: require("../../assets/images/about-profile-placeholder.jpg"),
          secondaryPhoto: DEFAULT_COVER,
        },
        {
          fullName: "Person McPerson",
          role: Roles.devOps,
          market: Markets.seattle,
          professionalPhoto: require("../../assets/images/about-profile-placeholder.jpg"),
          secondaryPhoto: DEFAULT_COVER,
        },
        {
          fullName: "Person McPerson",
          role: Roles.softwareEngineer,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/about-profile-placeholder.jpg"),
          secondaryPhoto: DEFAULT_COVER,
        },
      ],
    },
  },
  wrapPage: {
    message: `Event specific wrap message here: Lorem ipsum dolor sit amet.`,
    viewRecordingLink:
      "https://www.myworkday.com/slalom/learning/program/bf62d7e6149e100104907821aeca0000?type=2d29754fdb8e100008b50ff6bc94003b",
    feedbackEmailAddress: "mailto:slalomcitylimits@slalom.com",
    sharePointSiteLink:
      "https://twodegrees1.sharepoint.com/sites/SlalomCityLimits",
  },
  preLaunchPage: {
    message: `Event specific pre-launch message here: Lorem ipsum dolor sit amet,
    consectetur adipiscing elit. Adipiscing auctor rhoncus, massa
    condimentum nibh amet, pulvinar.`,
    spotifyPlayList:
      "https://open.spotify.com/playlist/3fFzw31inbjtRJOs18nyJM?si=8f9a17065adc4047",
    videoLink: "",
    lineup: [
      [
        "Mattie Bentley & Chris Shiman",
        "Keira Bethwaite & Demiah Hawkins",
        "Don Capuano",
        "Emily Webster",
        "Emily Foy & Ryan McCreedy",
        "Rizwan Janjua",
        "Will Bray",
        "Kevin Derman",
        "Adrian Letilovic",
      ],
      [
        "Benjamin Bratcher",
        "Stephanie Andersen & Kimberly Lewis & Kendall Toarmina",
        "Kristina Glynn",
        "Kristine Rohls",
        "Sara Eaton",
        "Brian McDaniel & Justin Hanson & Kevin Brake",
        "Caitlin Dowdall & Jill Regan & Jordan Krueger & Jena Rasmussen",
        "Taylor Davies",
        "Dan McBeth",
        "John Wheelock",
      ],
      [
        "Will Williams",
        "Chris Conley",
        "Ryan Dennis",
        "Giselle Pereira da Silva",
        "Mark Waks & Danielle Funston & Sam Shelby",
        "Avery Green",
        "Reedhima Mandlik",
        "Tobias Cortese",
      ],
      [
        "Denis Gassmann",
        "Siladitya Roy & Shailen Mistry & Samuel Armour",
        "Stevie Borne",
        "McCauley Hurt & Carrie Williams",
        "Justin Hanson & Dylan Rea",
        "Vinayak (Vin) Sen",
        "Jenn Schaefer",
        "Kristina Glynn",
      ],
    ],
  },
  winnersPage: {
    winnersEventsFeedbackText:
      "We always welcome feedback on our platform or the events we are hosting! Feel free to contact us.",
    winnersEventsJoinText:
      "Like what you see? Want experience building a custom platform in React? We welcome team members from every capability. Sign up via our link below!",
    winnersEventsBugText: "Please report all bugs through our link below!",
    recordingsLink:
      "https://www.myworkday.com/slalom/learning/program/bf62d7e6149e100104907821aeca0000?type=2d29754fdb8e100008b50ff6bc94003b",
    winnersEventAnnouncementText:
      "Thank you for making this another successful year for Slalom City Limits! We hope you all walk away from the festival feeling informed, inspired, and connected securely. Want to relive the fun moments or hoping to catch a session you missed? Check out the recordings in ",
    winnersEventsFansText: "Top 3 Fans will receive a digital gift card!",
    winnersEventsArtistsText: "Top 3 Artists will receive a digital gift card!",
  },
  contact: {
    reportBugEmail: "zenith@slalom.com",
    feedbackEmail: "zenith@slalom.com",
  },
};
