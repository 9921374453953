import React from "react";
import CountUp from "react-countup";
import { ParticipantRank } from "../../models/participant.model";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";
import "./Rankings.scss";

type RankingsProps = {
  participantData: ParticipantRank;
};

const Rankings: React.FC<RankingsProps> = ({ participantData }) => {
  const { width } = useWindowSize();
  const { TABLET_MAX } = deviceWidthBreakpoints;

  return (
    <>
      {width > TABLET_MAX && (
        <div className="rankings-container">
          <span className="local-ranking">
            <span>Local: #</span>
            {participantData?.marketRank && (
              <CountUp end={participantData?.marketRank} duration={2} />
            )}
          </span>
          <span className="global-ranking">
            <span>Global: #</span>
            {participantData?.globalRank && (
              <CountUp end={participantData?.globalRank} duration={2} />
            )}
          </span>
        </div>
      )}
    </>
  );
};

export default Rankings;
