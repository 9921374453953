import { FC } from "react";
import PointsCard from "../../../components/PointsCard/PointsCard";
import { Market } from "../../../models/market.model";
import { ParticipantRank } from "../../../models/participant.model";
import { getDataImage } from "../../../utils/imageUtils";
import { LeaderListingProps } from "../Leaderboard.interface";

const LeaderListing: FC<LeaderListingProps> = ({
  rank,
  data,
  type,
  lastRow = false,
}) => {
  const isMarket = type === "Market";
  return (
    <div
      className={`leader-listing ${
        lastRow ? "leader-listing:last-child" : ""
      } ${isMarket && "leader-listing-market"}`}
    >
      <div className="leader-listing__rank listing-text-style">{rank}</div>
      <div className="leader-listing__participant ">
        {!isMarket && (
          <img
            src={getDataImage((data as ParticipantRank).image)}
            className="leader-listing__avatar"
            alt="avatar"
          />
        )}
        <div className="listing-text-style">
          {(data as Market).name ||
            `${(data as ParticipantRank).firstName} ${
              (data as ParticipantRank).lastName
            }`}
        </div>
      </div>
      <div>{(data as ParticipantRank).market}</div>
      <PointsCard text={data.totalPoints + " Points"} />
    </div>
  );
};

export default LeaderListing;
